const tags = [
    { id: 1, name: 'community', category: 'guilds', show: true },
    { id: 2, name: 'games', category: 'guilds', show: true },
    { id: 3, name: 'fun', category: 'guilds', show: true },
    { id: 4, name: '18+', category: 'guilds', show: true },
    { id: 5, name: 'sports', category: 'guilds', show: true },
    { id: 6, name: 'news', category: 'guilds', show: true },
    { id: 7, name: 'music', category: 'guilds', show: true },
    { id: 8, name: 'tech', category: 'guilds', show: true },
    { id: 9, name: 'art', category: 'guilds', show: true },
    { id: 10, name: 'science', category: 'guilds', show: true },
]
  
export default tags
