import axios from 'axios'
import { StepContext } from '../StepContext'
import { useState, useEffect, useContext } from 'react'
import { getIconUrl, createIcon } from '../../../../../utils/guilds.jsx'

const API_URL = import.meta.env.VITE_BACKEND_URL

export default function GuildBotAddition() {
    const [loading, setLoading] = useState(true)
    const [currentGuild, setCurrentGuild] = useState(null)
    const { userGuilds, guildId, botStatus, setBotStatus, setGuildInfo, handleNext, handleBack } = useContext(StepContext)

    const checkBotStatus = async (guildId) => {
        try {
            const token = localStorage.getItem('net:token')
            const response = await axios.get(
                `${API_URL}/guilds/${guildId}/exists`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            )
            return response.data

        } catch (error) {
            console.error('Ошибка при получении присутсвия бота: ', error)
            return { ok: false, exists: false }
        }
    }

    useEffect(() => {
        if (guildId) {
            checkBotPresence()
            const guild = userGuilds.find(guild => guild.id === guildId)
            setCurrentGuild(guild)
        }
    }, [guildId])

    const checkBotPresence = async () => {
        const response = await checkBotStatus(guildId)
        if (response.exists) {
            setBotStatus(true)
            setGuildInfo((prev) => ({...prev,
                members: response.members,
                createdAt: response.created_at
            }))
        }

        setLoading(false)
    }

    const addBotUrl = [
        'https://discord.com/oauth2/authorize?',
        'client_id=901910600148471880',
        '&permissions=116736',
        '&scope=bot%20applications.commands',
        `&guild_id=${guildId}`,
        '&disable_guild_select=true'
    ].join('')

    const handleButtonClick = async (event) => {
        event.preventDefault()

        const response = await checkBotStatus(guildId)
        if (response.exists) {
            setBotStatus(true)
            setGuildInfo((prev) => ({...prev,
                members: response.members,
                createdAt: response.created_at
            }))
            handleNext()
        } else {
            window.open(addBotUrl, '_blank', 'noopener,noreferrer')
        }

        setLoading(false)
    }

    return (
        <div className="bg-net-grey-500 border border-net-grey-450 p-6 rounded-lg shadow-lg">
            <div className="flex items-center space-x-4 mb-4">
                <img src='/logos/netrix-512.png' alt="Netrix Logo" className="w-16 h-16 rounded-full shadow-md" />
                <div className="text-3xl font-bold">
                    Netrix Team
                    <span className='text-net-grey-300 font-medium text-2xl'>#2900</span>
                    <p className="text-white/90 text-sm font-medium italic mt-1">This is the official <span className='text-net-purple-500'>Netrix Team</span> bot</p>
                </div>
            </div>

            <div className="text-white mb-4 max-w-2xl">
                <p className="mb-2">
                    We need to add our bot to the server for synchronization
                </p>
            </div>

            {currentGuild && (  // Отображаем блок с гильдией, если она найдена
                <div className="flex items-center space-x-4 bg-net-white-50 p-4 rounded-lg shadow-md mb-4">
                    {currentGuild.icon ? (
                        <>
                            <img src={getIconUrl(currentGuild.id, currentGuild.icon)} alt={currentGuild.name} className="w-12 h-12 rounded-full shadow-md" />
                        </>
                    ) : (
                        <>
                            {createIcon({ name: currentGuild.name, textSize: 'text-2xl', width: 'w-12', height: 'h-12' })}
                        </>
                    )}
                    <div>
                        <p className="text-white text-lg font-bold">The bot will be added to:</p>
                        <p className="text-net-grey-300 text-base">{currentGuild.name}</p>
                    </div>
                </div>
            )}

            <div className="mt-6">
                <div className="bg-net-white-50 p-4 rounded-lg shadow-md">
                    <p className="font-bold text-white mb-2">Notes:</p>
                    <ul className="text-sm text-net-grey-300 list-disc list-inside">
                        <li>Make sure you have granted the necessary access rights to the bot.</li>
                        <li>If you are having difficulties, please contact our <a href="/support" className="text-net-purple-500 hover:text-net-purple-900 duration-150">support team</a> for assistance.</li>
                    </ul>
                </div>
            </div>

            <hr className="border-t border-net-grey-450 my-4" /> {/* Полоска перед кнопками */}

            <div className="flex justify-end space-x-4 mt-6">
                <button onClick={handleBack} className="bg-net-white-100 text-white text-lg rounded-lg py-3 px-6 duration-150 hover:bg-net-white-200">
                    Back
                </button>
                <button
                    className={`px-4 py-2 text-lg rounded-lg shadow-lg transition-colors duration-150 ease-in-out ${botStatus ? 'bg-net-purple-500 hover:bg-net-purple-900' : 'bg-net-white-100 hover:bg-net-white-200'} text-white`}
                    onClick={handleButtonClick}
                    disabled={loading}
                >
                    {loading ? 'Checking...' : botStatus ? 'Next' : 'Add'}
                </button>
            </div>
        </div>
    )
}
