const discord_cdn = 'https://cdn.discordapp.com'

export const getAvatarUrl = (user_id, avatarHash) => {
    if (!user_id || !avatarHash) {
        return getDefaultAvatarUrl()
    }

    const extension = avatarHash.startsWith('a_') ? '.gif' : '.png'
    return `${discord_cdn}/avatars/${user_id}/${avatarHash}${extension}`
}

export const getDefaultAvatarUrl = () => {
    const number = Math.floor(Math.random() * 6)
    return `${discord_cdn}/embed/avatars/${number}.png`
}
