import axios from 'axios'
import { useState, Fragment } from 'react'
import { Star } from 'lucide-react'
import toast from 'react-hot-toast'
import { getAvatarUrl } from '@utils/avatars.js'
import { Progress } from 'antd'
import { IoInformationCircleOutline } from 'react-icons/io5'
import { useForm } from 'react-hook-form'
import { formatDistanceToNow } from 'date-fns'
import { FiMoreVertical, FiEdit2 } from 'react-icons/fi'
import { IoIosClose } from 'react-icons/io'
import { Menu, MenuItem, MenuItems, MenuButton } from '@headlessui/react'

export default function ReviewStats({ ratings, reviews, type, object_id, author }) {
    const defaultRatings = {
        '1': 17,
        '2': 8,
        '3': 16,
        '4': 75,
        '5': 105,
    }

    const { register, formState } = useForm({ mode: 'onChange' })
    const formErrors = formState.errors
    const commentError = formErrors.comment?.message

    const [reviewsState, setReviewsState] = useState(reviews || [])

    const [commentLength, setCommentLength] = useState(0)
    const [hoveredStar, setHoveredStar] = useState(0)
    const [reviewRating, setReviewRating] = useState(0)
    const [reviewComment, setReviewComment] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)

    // Состояния для редактирования
    const [isEditing, setIsEditing] = useState(false)
    const [editingReviewId, setEditingReviewId] = useState(null)

    const defaultClasses = 'border-net-grey-450 bg-transparent focus:bg-slate-500/10'
    const errorClasses = 'border-red-500 bg-red-500/10 focus:border-red-500 focus:bg-red-500/10'

    const safeRatings = ratings || defaultRatings

    const totalReviews = Object.values(safeRatings).reduce((sum, count) => sum + count, 0)
    const weightedSum = Object.entries(safeRatings).reduce(
        (sum, [rating, count]) => sum + Number(rating) * count,
        0
    )
    const averageRating = totalReviews > 0 ? weightedSum / totalReviews : 0

    const userReview = reviewsState.find((review) => review.author?.id === author?.id)

    const renderStars = (rating, isInteractive = false) => {
        const stars = []
        for (let i = 1; i <= 5; i++) {
            stars.push(
                <Star
                    key={i}
                    className={`w-6 h-6 ${
                        isInteractive
                            ? 'cursor-pointer hover:fill-net-purple-500/40 hover:stroke-net-purple-500 hover:stroke-1'
                            : ''
                    } ${
                        i <= (isInteractive ? hoveredStar || reviewRating : rating)
                            ? 'fill-net-purple-500/40 stroke-net-purple-500 stroke-1'
                            : 'fill-net-white-50 stroke-net-white-100 stroke-1'
                    }`}
                    onMouseEnter={() => isInteractive && setHoveredStar(i)}
                    onMouseLeave={() => isInteractive && setHoveredStar(0)}
                    onClick={() => isInteractive && setReviewRating(i)}
                />
            )
        }
        return stars
    }

    const renderReviewDate = (created_at) => {
        if (!created_at) return null

        const timestamp = Number(created_at)
        if (!timestamp || isNaN(timestamp)) {
            return null
        }

        const adjustedTimestamp = timestamp > 1000000000000 ? timestamp : timestamp * 1000
        const date = new Date(adjustedTimestamp)

        if (isNaN(date.getTime())) {
            return null
        }

        return formatDistanceToNow(date, { addSuffix: true }).replace('about ', '')
    }

    const handleReviewSubmit = async () => {
        if (reviewRating === 0 || reviewComment.trim() === '') {
            toast.error('Please select a rating and write a comment.')
            return
        }

        setIsSubmitting(true)

        try {
            const token = localStorage.getItem('net:token')
            const response = await axios.post(
                `${import.meta.env.VITE_BACKEND_URL}/reviews/`,
                {
                    objectId: object_id,
                    objectType: type,
                    rating: reviewRating,
                    message: reviewComment.trim(),
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )

            toast.success('Review submitted successfully!')

            // Формируем новый отзыв на основе имеющихся данных
            const created_at = response.data.created_at // время создания берем из ответа
            const newLocalReview = {
                id: Math.random().toString(36).substring(2, 9), // временный id, можно генерировать рандомно
                author: {
                    id: author.id,
                    username: author.username,
                    avatar: author.avatar
                },
                rating: reviewRating,
                message: reviewComment.trim(),
                created_at: created_at // используем дату с сервера
            }

            // Добавляем новый отзыв в состояние
            setReviewsState((prev) => [...prev, newLocalReview])

            setReviewRating(0)
            setReviewComment('')
        } catch (error) {
            toast.error(error.response?.data?.detail || 'Error submitting review')
        } finally {
            setIsSubmitting(false)
        }
    }

    const handleEditReview = (review) => {
        setReviewRating(review.rating)
        setReviewComment(review.message)
        setEditingReviewId(review.id)
        setIsEditing(true)
    }

    const handleSaveReview = async () => {
        if (reviewRating === 0 || reviewComment.trim() === '') {
            toast.error('Please select a rating and write a comment.')
            return
        }

        setIsSubmitting(true)

        try {
            const token = localStorage.getItem('net:token')
            await axios.put(
                `${import.meta.env.VITE_BACKEND_URL}/reviews/${editingReviewId}`,
                { rating: reviewRating, message: reviewComment.trim() },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            toast.success('Review updated successfully!')

            setReviewsState((prev) =>
                prev.map((r) =>
                    r.id === editingReviewId ? { ...r, rating: reviewRating, message: reviewComment.trim() } : r
                )
            )

            setIsEditing(false)
            setEditingReviewId(null)
            setReviewRating(0)
            setReviewComment('')
        } catch (error) {
            toast.error(error.response?.data?.detail || 'Error updating review')
        } finally {
            setIsSubmitting(false)
        }
    }

    const handleDeleteReview = async (reviewId) => {
        if (!confirm('Are you sure you want to delete this review?')) return

        try {
            const token = localStorage.getItem('net:token')
            await axios.delete(`${import.meta.env.VITE_BACKEND_URL}/reviews/${reviewId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            toast.success('Review deleted successfully!')

            setReviewsState((prev) => prev.filter((r) => r.id !== reviewId))
        } catch (error) {
            toast.error(error.response?.data?.detail || 'Error deleting review')
        }
    }

    const handleCancelEdit = () => {
        setIsEditing(false)
        setEditingReviewId(null)
        setReviewRating(0)
        setReviewComment('')
    }

    return (
        <div className="w-full">
            <h2 className="text-2xl font-bold mb-6">Reviews</h2>
            <div className="flex space-x-10">
                <div className="flex flex-col items-start justify-between w-1/3">
                    <div className="flex items-center space-x-2">
                        <div className="flex items-center mb-2">
                            <span className="text-6xl font-bold">{averageRating.toFixed(1)}</span>
                        </div>
                        <div className="flex flex-col items-center">
                            <div className="flex">{renderStars(averageRating)}</div>
                            <div className="text-net-grey-300 text-sm font-medium mt-2">
                                {totalReviews.toLocaleString()} reviews
                            </div>
                        </div>
                    </div>

                    <p className="text-net-grey-300 text-sm font-medium flex items-center">
                        <IoInformationCircleOutline className="text-4xl mr-2" />
                        All reviews are subject to check and moderation
                    </p>
                </div>

                <div className="space-y-2 w-full">
                    {Object.entries(safeRatings)
                        .sort((a, b) => Number(b[0]) - Number(a[0]))
                        .map(([rating, count]) => (
                            <div key={rating} className="flex items-center">
                                <div className="w-14 text-sm font-medium flex items-center">
                                    {rating}{' '}
                                    <Star className="w-4 h-4 ml-1 fill-net-purple-500/40 stroke-net-purple-500 stroke-1" />
                                </div>
                                <Progress
                                    percent={totalReviews ? (count / totalReviews) * 100 : 0}
                                    showInfo={false}
                                    strokeColor="#7f3bf5"
                                    trailColor="#2a2d38"
                                />
                                <div className="w-12 text-sm text-right">{count.toLocaleString()}</div>
                            </div>
                        ))}
                </div>
            </div>

            <hr className="border-t border-net-grey-450 my-8" />

            {author?.id && author?.username ? (
                userReview ? null : (
                    <>
                        <div className="w-full mt-8">
                            <div className="flex flex-col space-y-4">
                                <div className="flex space-x-4 items-center">
                                    <img
                                        src={getAvatarUrl(author.id, author.avatar)}
                                        alt="Author Avatar"
                                        className="w-12 h-12 rounded-full"
                                    />
                                    <div className="flex flex-col items-start space-y-1">
                                        <span className="text-lg font-medium">{author.username}</span>
                                        <div className="flex">{renderStars(reviewRating, true)}</div>
                                    </div>
                                </div>

                                <div className="flex-grow">
                                    <textarea
                                        autoComplete="off"
                                        id="comment"
                                        maxLength={255}
                                        value={reviewComment}
                                        placeholder="Write your review here (max 255 characters)..."
                                        className={`w-full min-h-20 max-h-52
                                            px-4 py-2 border rounded-lg
                                            outline-none transition-colors
                                            ease-linear duration-300
                                            ${commentError ? errorClasses : defaultClasses}`}
                                        {...register('comment', {
                                            required: {
                                                value: true,
                                                message: 'This is a required field',
                                            },
                                            minLength: {
                                                value: 10,
                                                message: 'Minimum of 10 characters',
                                            },
                                            onChange: (event) => {
                                                const value = event.target.value
                                                setCommentLength(value.length)
                                                setReviewComment(value)
                                            },
                                        })}
                                    />
                                    <div className="flex justify-between items-center mt-1">
                                        <span className="text-sm text-red-500">{commentError}</span>
                                        <div className="text-sm text-net-grey-300">{commentLength}/255</div>
                                    </div>
                                </div>

                                <div className="flex justify-end">
                                    <button
                                        disabled={isSubmitting}
                                        onClick={handleReviewSubmit}
                                        className="bg-net-purple-500 hover:bg-net-purple-900 transition-colors duration-150 w-32 px-4 py-2 text-white rounded-lg font-medium disabled:opacity-50 disabled:cursor-not-allowed"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>

                        <hr className="border-t border-net-grey-450 my-8" />
                    </>
                )
            ) : (
                <>
                    <p className="text-center text-gray-500 mt-8">
                        Please log in to leave a review.
                    </p>

                    <hr className="border-t border-net-grey-450 my-8" />
                </>
            )}

            <div className="w-full mt-8">
                {reviewsState.length > 0 && (
                    <h2 className="text-2xl font-bold mb-4">Comments</h2>
                )}

                {reviewsState.map((review) =>
                    isEditing && editingReviewId === review.id ? (
                        <div key={review.id} className="w-full my-8">
                            <div className="flex flex-col space-y-2">
                                <div className="flex space-x-2 items-center">
                                    <img
                                        src={getAvatarUrl(review.author?.id, review.author?.avatar)}
                                        alt="Reviewer Avatar"
                                        className="w-12 h-12 rounded-full"
                                    />
                                    <div className="flex justify-between items-center w-full">
                                        <div className="flex flex-col items-start space-y-1">
                                            <span className="text-lg font-medium">{review.author?.username}</span>
                                            <div className="flex">{renderStars(reviewRating, true)}</div>
                                        </div>

                                        <button
                                            onClick={handleCancelEdit}
                                            className="hover:bg-slate-500/10 transition-colors duration-150 rounded-lg"
                                        >
                                            <IoIosClose className='w-8 h-8' />
                                        </button>
                                    </div>
                                </div>
                                <div className="flex-grow">
                                    <textarea
                                        autoComplete="off"
                                        maxLength={255}
                                        value={reviewComment}
                                        placeholder="Edit your review here..."
                                        className={`w-full min-h-20 max-h-52
                                            px-4 py-2 border rounded-lg
                                            outline-none transition-colors
                                            ease-linear duration-300
                                            ${commentError ? errorClasses : defaultClasses}`}
                                        onChange={(e) => setReviewComment(e.target.value)}
                                    />
                                    <div className="flex justify-end mt-2">
                                        <button
                                            disabled={isSubmitting}
                                            onClick={handleSaveReview}
                                            className="bg-net-purple-500 hover:bg-net-purple-900 transition-colors duration-150 px-4 py-2 text-white rounded-lg font-medium disabled:opacity-50 disabled:cursor-not-allowed"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        // Обычный вывод комментария
                        <div key={review.id} className='flex justify-between py-2 mb-4'>
                            <div>
                                <div className="flex items-center mb-2">
                                    <img
                                        src={getAvatarUrl(review.author?.id, review.author?.avatar)}
                                        alt="Reviewer Avatar"
                                        className="w-10 h-10 rounded-full mr-2"
                                    />
                                    <div>
                                        <p className="font-bold">{review.author?.username}</p>
                                        <div className="flex">{renderStars(review.rating)}</div>
                                    </div>
                                </div>
                                <p className="text-sm mb-2">{review.message}</p>
                                <p className="text-xs text-gray-500">
                                    {renderReviewDate(review.created_at)}
                                </p>
                            </div>
                            
                            {author?.id === review.author?.id ? (
                                <Menu as='div' className='relative inline-block text-left'>
                                    <MenuButton className="flex items-center">
                                        <FiMoreVertical className="text-net-grey-300 transition-colors duration-300 hover:text-net-grey-300/60 w-5 h-5" />
                                    </MenuButton>

                                    <MenuItems 
                                        transition
                                        className='absolute right-0 mt-2 w-44 bg-net-grey-500 border 
                                        border-net-grey-450 rounded-lg focus:outline-none z-10 origin-top-left 
                                        transition ease-out data-[closed]:scale-95 data-[closed]:opacity-0'>

                                        <div className='p-2'>
                                            <MenuItem as={Fragment}>
                                                <button
                                                    onClick={() => handleEditReview(review)}
                                                    className='flex items-center px-3 py-1.5 text-md text-white 
                                                    data-[focus]:text-white data-[disabled]:opacity-50 w-full
                                                    data-[disabled]:cursor-not-allowed data-[disabled]:text-white 
                                                    font-medium rounded-lg hover:bg-slate-500/10 duration-150'>

                                                    <FiEdit2 className='w-4 h-4 mr-2' />
                                                    Edit
                                                </button>
                                            </MenuItem>

                                            <MenuItem as={Fragment}>
                                                <button
                                                    onClick={() => handleDeleteReview(review.id)}
                                                    className='flex items-center px-3 py-1.5 text-md w-full
                                                    text-red-500 data-[focus]:text-red-500 data-[disabled]:opacity-50
                                                    data-[disabled]:cursor-not-allowed data-[disabled]:text-red-500
                                                    font-medium rounded-lg hover:bg-red-500/10 duration-150'>

                                                    <i className='fi fi-rr-trash-list text-lg leading-none mr-2'></i>
                                                    Delete
                                                </button>
                                            </MenuItem>
                                        </div>
                                    </MenuItems>
                                </Menu>
                            ) : (
                                <Menu as='div' className='relative inline-block text-left'>
                                    <MenuButton className="flex items-center">
                                        <FiMoreVertical className="text-net-grey-300 transition-colors duration-300 hover:text-net-grey-300/60 w-5 h-5" />
                                    </MenuButton>

                                    <MenuItems 
                                        transition
                                        className='absolute right-0 mt-2 w-44 bg-net-grey-500 border 
                                        border-net-grey-450 rounded-lg focus:outline-none z-10 origin-top-left 
                                        transition ease-out data-[closed]:scale-95 data-[closed]:opacity-0'>

                                        <div className='p-2'>
                                            <MenuItem as={Fragment} disabled={true}>
                                                <button
                                                    className='flex items-center px-3 py-1.5 text-md w-full
                                                    text-red-500 data-[focus]:text-red-500 data-[disabled]:opacity-50
                                                    data-[disabled]:cursor-not-allowed data-[disabled]:text-red-500
                                                    font-medium rounded-lg hover:bg-red-500/10 duration-150'>

                                                    <i className='fi fi-br-triangle-warning text-lg leading-none mt-1 mr-2'></i>
                                                    Report
                                                </button>
                                            </MenuItem>
                                        </div>
                                    </MenuItems>
                                </Menu>
                            )}
                        </div>
                    )
                )}
            </div>
        </div>
    )
}
