import axios from 'axios'
import toast from 'react-hot-toast'
import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '@components/auth/AuthContext'
import StepProvider from './components/StepContext'

const API_URL = import.meta.env.VITE_BACKEND_URL

const AddGuildPage = () => {
    const { auth } = useContext(AuthContext)
    const [userGuilds, setUserGuilds] = useState([])

    useEffect(() => {
        async function fetchUserGuilds() {
            if (!auth.isAuthenticated) {
                console.log('User not authenticated')
                toast.error('You must be authenticated')
                return
            }

            if (!auth.jwt.aToken) {
                console.log('Access Token is required')
                toast.error('Invalid JWT Token')
                return
            }

            const token = localStorage.getItem('net:token')
    
            try {
                axios.get(`${API_URL}/users/me/guilds`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }).then((response) => {
                    setUserGuilds(response.data)
                })
            } catch (error) {
                if (error.response && error.response.status === 429) {
                    console.warn('Too many requests, Retrying in 1 minute')
                    toast('Too many requests. Repeat in 1 minute', {icon: '⌛'})
                }
            }
        }

        fetchUserGuilds()
    }, [auth.isAuthenticated, auth.jwt])    

    return (
        <div className='max-w-7xl mx-auto'>
            <div className='mt-4 mb-16'>
                <h1 className='text-5xl font-bold mb-4'>Add your guild</h1>
                <span className='text-3xl text-net-grey-300 font-medium'>Follow the steps to configure a guild</span>
            </div>

            <StepProvider userGuilds={userGuilds} />
        </div>
    )
}

export default AddGuildPage