import React from 'react'

function Footer() {
    return (
        <footer className='bg-net-grey-500 py-6 mt-20'>
            <div className='container mx-auto text-center text-white'>
                <p>© 2024 <span className='font-medium'>Netrix Team</span>. All rights reserved.</p>
            </div>
        </footer>
    )
}

export default Footer
