export const handleTagChange = (selectedTags, id) => {
    return selectedTags.includes(id)
        ? selectedTags.filter(tagId => tagId !== id)
        : [...selectedTags, id]
}

export const getVisibleTags = (tags, showAll, limit = 7) => {
    const visibleTags = tags.filter(tag => tag.show)
    return showAll ? visibleTags : visibleTags.slice(0, limit)
}

export const mapTagIdsToTags = (tagIds, allTags) => {
    return tagIds.map(tagId => allTags.find(tag => tag.id === tagId))
}
