import React, { useState, useEffect, useContext } from 'react'
import { AuthContext } from '@components/auth/AuthContext.jsx'
import { useForm, useFieldArray } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption
} from '@headlessui/react'
import toast from 'react-hot-toast'

const API_URL = import.meta.env.VITE_BACKEND_URL

const socials = {
    github: { 
        icon: 'fi fi-brands-github', 
        colors: ['#404040'], 
        baseUrl: 'https://github.com/',
        label: 'GitHub'
    },
    gitlab: { 
        icon: 'fi fi-brands-gitlab', 
        colors: ['#e14328', '#fc6b0e', '#ff9e1b'], 
        baseUrl: 'https://gitlab.com/',
        label: 'GitLab'
    },
    tiktok: { 
        icon: 'fi fi-brands-tik-tok', 
        colors: ['#25f4ee', '#ff0050'], 
        baseUrl: 'https://tiktok.com/@',
        label: 'TikTok'
    },
    twitter: { 
        icon: 'fi fi-brands-twitter-alt', 
        colors: ['#3e4c57'], 
        baseUrl: 'https://twitter.com/',
        label: 'Twitter'
    },
    my_site: { 
        icon: 'fi fi-rr-browser', 
        colors: ['#8cc63e'], 
        baseUrl: '',
        label: 'My Website'
    },
    telegram: { 
        icon: 'fi fi-brands-telegram', 
        colors: ['#0088CC'], 
        baseUrl: 'https://t.me/',
        label: 'Telegram'
    },
    instagram: { 
        icon: 'fi fi-brands-instagram', 
        colors: ['#F58529', '#DD2A7B', '#833AB4'], 
        baseUrl: 'https://instagram.com/',
        label: 'Instagram'
    },
    discord: { 
        icon: 'fi fi-brands-discord', 
        colors: ['#5865F2'], 
        baseUrl: 'https://discord.com/users/',
        label: 'Discord'
    }
}

const ProfileSettingsPage = () => {
    const navigate = useNavigate()
    const { auth } = useContext(AuthContext)
    const [userInfo, setUserInfo] = useState(null)
    const [selectedSocial, setSelectedSocial] = useState(null)

    const [initialValues, setInitialValues] = useState({ status: '', socials: [] })

    const { register, handleSubmit, control, formState: { errors, isValid, isDirty }, setValue, reset } = useForm({
        mode: 'onChange',
        defaultValues: {
            status: '',
            socials: []
        }
    })

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'socials'
    })

    const [statusLength, setStatusLength] = useState(0)

    useEffect(() => {
        const token = localStorage.getItem('net:token')
        axios.get(`${API_URL}/users/me`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            setUserInfo(response.data)

            if (response.data && response.data.user) {
                const { status, socials: userSocials } = response.data.user
                let socialsArray = []
                if (userSocials) {
                    socialsArray = Object.entries(userSocials)
                        .filter(([platform, value]) => value && socials[platform] && platform !== 'discord')
                        .map(([platform, value]) => ({
                            platform,
                            username: value
                        }))
                }

                const loadedValues = {
                    status: status || '',
                    socials: socialsArray
                }

                setInitialValues(loadedValues)
                reset(loadedValues)
                setStatusLength((status || '').length)
            }
        })
    }, [auth.isAuthenticated, auth.jwt, reset])

    const onSubmit = (data) => {
        const token = localStorage.getItem('net:token')

        const socialsObject = {}
        data.socials.forEach(s => {
            socialsObject[s.platform] = s.username
        })

        const payload = {
            status: data.status,
            socials: socialsObject
        }

        axios.patch(`${API_URL}/users/me`, payload, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(() => {
            toast.success('Profile successfully updated')

            const newValues = {
                status: data.status,
                socials: data.socials
            }
            setInitialValues(newValues)
            reset(newValues)
            setStatusLength(data.status.length)

            navigate('/profile')
        }).catch(err => {
            console.error(err)
        })
    }

    const handleAddSocial = (platform) => {
        if (!platform) return
        const alreadyExists = fields.some(field => field.platform === platform)
        if (!alreadyExists) {
            append({ platform, username: '' })
        }
        setSelectedSocial(null)
    }

    if (!userInfo) {
        return null
    }

    // Доступные платформы, без discord
    const availablePlatforms = Object.entries(socials)
        .filter(([platform]) => platform !== 'discord')
        .filter(([platform]) => !fields.some(field => field.platform === platform))
        .map(([platform, data]) => ({ value: platform, label: data.label }))

    const hexToRgba = (hex, alpha) => {
        hex = hex.replace('#', '')
        let r = 0, g = 0, b = 0

        if (hex.length === 3) {
            r = parseInt(hex[0] + hex[0], 16)
            g = parseInt(hex[1] + hex[1], 16)
            b = parseInt(hex[2] + hex[2], 16)
        } else if (hex.length === 6) {
            r = parseInt(hex.substring(0, 2), 16)
            g = parseInt(hex.substring(2, 4), 16)
            b = parseInt(hex.substring(4, 6), 16)
        }

        return `rgba(${r}, ${g}, ${b}, ${alpha})`
    }

    const renderSocialIcon = (platform) => {
        const { icon, colors } = socials[platform]
        const transparentColors = colors.map(color => hexToRgba(color, 0.4))
        const backgroundGradient =
            colors.length > 1
                ? `linear-gradient(45deg, ${transparentColors.join(', ')})`
                : transparentColors[0]

        const borderGradient =
            colors.length > 1
                ? `linear-gradient(45deg, ${colors.join(', ')})`
                : colors[0]

        return (
            <div 
                className="relative flex items-center justify-center w-10 h-10 cursor-default rounded-lg overflow-hidden"
                style={{
                    color: '#fff',
                    background: backgroundGradient,
                }}
            >
                <i className={`${icon} text-xl leading-none text-white relative top-[2.5px] z-10`}></i>
                <span
                    className="absolute inset-0 rounded-lg"
                    style={{
                        padding: '1px',
                        background: borderGradient,
                        mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                        WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                        maskComposite: 'exclude',
                        WebkitMaskComposite: 'xor',
                        pointerEvents: 'none',
                    }}
                ></span>
            </div>
        )
    }

    const defaultClasses = 'border-net-grey-450 bg-transparent text-white focus:bg-slate-500/10'
    const errorClasses = 'border-red-500 bg-red-500/10 focus:border-red-500 focus:bg-red-500/10'

    const menuButtonStyle = `
        flex items-center px-3 py-2.5 text-md text-white 
        data-[focus]:text-white data-[disabled]:opacity-50 
        data-[disabled]:cursor-not-allowed data-[disabled]:text-white 
        font-medium rounded-lg hover:bg-slate-500/10 duration-150 cursor-pointer
    `

    const statusError = errors.status?.message
    const saveDisabled = !isValid || !isDirty

    return (
        <div className='max-w-3xl mx-auto mt-10'>
            <div className='mb-4'>
                <button
                    onClick={() => navigate('/profile')}
                    className='px-4 py-2 bg-net-grey-500 hover:bg-net-grey-400 transition-colors duration-300 border border-net-grey-450 rounded-lg font-medium'
                >
                    <i className="fi fi-rr-left mr-2 relative top-[2px]"></i>
                    Back to Profile
                </button>
            </div>

            <div className="bg-net-grey-500 p-8 rounded-lg border border-net-grey-450">
                <h1 className="text-3xl font-bold mb-6">Profile Settings</h1>
            
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
            
                    {/* Status */}
                    <div className="flex flex-col space-y-2">
                        <label className="font-semibold text-lg">Status</label>
                        <input
                            type="text"
                            placeholder="Enter your status"
                            maxLength={55}
                            className={`
                                w-full p-3 rounded-md border outline-none transition-colors ease-linear duration-300
                                ${statusError ? errorClasses : defaultClasses}
                            `}
                            {...register('status', {
                                minLength: {
                                    value: 5,
                                    message: 'Minimum of 5 characters'
                                },
                                maxLength: {
                                    value: 55,
                                    message: 'Maximum of 55 characters'
                                },
                                onChange: (event) => {
                                    const value = event.target.value
                                    setValue('status', value)
                                    setStatusLength(value.length)
                                }
                            })}
                        />
                        <div className='flex justify-between items-center mt-1'>
                            <span className='text-sm text-red-500'>{statusError}</span>
                            <div className='text-sm text-net-grey-300'>
                                {statusLength}/55
                            </div>
                        </div>
                    </div>
                    {/* Social links */}
                    <div className="space-y-4">
                        <div className="flex items-center justify-between">
                            <div>
                                <h2 className="text-xl font-semibold">Social Links</h2>
                                <p className="text-net-grey-300">Select a social network and provide your username (or a link for your website).</p>
                            </div>
                            {availablePlatforms.length > 0 && (
                                <Listbox value={selectedSocial} onChange={handleAddSocial}>
                                    <div className="relative w-64">
                                        <ListboxButton className="relative w-full cursor-pointer rounded-lg bg-net-grey-500 border border-net-grey-450 p-3 text-left outline-none">
                                            <span className="block truncate">{selectedSocial ? socials[selectedSocial]?.label : 'Select a social network'}</span>
                                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                <i className="fi fi-rr-angle-small-down"></i>
                                            </span>
                                        </ListboxButton>
                                        <ListboxOptions transition className="absolute mt-1 p-2 w-full transition-all ease-out data-[closed]:scale-95 data-[closed]:opacity-0 bg-net-grey-500 border border-net-grey-450 rounded-md shadow-lg z-10">
                                            {availablePlatforms.map((platformObj, idx) => (
                                                <ListboxOption
                                                    key={idx}
                                                    value={platformObj.value}
                                                    className={menuButtonStyle}>
                                                    {platformObj.label}
                                                </ListboxOption>
                                            ))}
                                        </ListboxOptions>
                                    </div>
                                </Listbox>
                            )}
                        </div>
                        {/* List of added socials */}
                        <div className="space-y-3">
                            {fields.map((field, index) => {
                                const platform = field.platform
                                const isMySite = platform === 'my_site'
                                const hasError = errors.socials && errors.socials[index] && errors.socials[index].username
                                return (
                                    <div key={field.id} className="flex items-center space-x-3">
                                        {renderSocialIcon(platform)}
                                        <div className="flex-1">
                                            <input
                                                {...register(`socials.${index}.username`, {
                                                    required: true,
                                                    pattern: isMySite ? /^https?:\/\/.+/i : undefined,
                                                    onChange: (event) => {
                                                        const value = event.target.value
                                                        setValue(`socials.${index}.username`, value)
                                                    }
                                                })}
                                                type="text"
                                                placeholder={isMySite ? 'https://example.com' : 'username'}
                                                className={`
                                                    w-full p-2 rounded-md border outline-none transition-colors duration-300
                                                    ${hasError ? errorClasses : defaultClasses}
                                                `}
                                            />
                                        </div>
                                        <button
                                            type="button"
                                            onClick={() => remove(index)}
                                            className="text-red-500 hover:text-red-400 transition-colors duration-150 text-xl"
                                            title="Remove this social link"
                                        >
                                            <i className="fi fi-rr-trash"></i>
                                        </button>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {/* Buttons row */}
                    <div className="flex justify-end space-x-4">
                        <button
                            type="button"
                            disabled={!isDirty}
                            onClick={() => {
                                reset(initialValues)
                                setStatusLength(initialValues.status.length)
                            }}
                            className={`px-6 py-3 text-md rounded-lg transition-colors duration-300 ease-in-out text-white font-semibold ${
                                !isDirty
                                    ? 'bg-net-grey-450 cursor-not-allowed'
                                    : 'bg-blue-500 hover:bg-blue-600'
                            }`}
                        >
                            Reset
                        </button>
                        <button
                            type="submit"
                            disabled={saveDisabled}
                            className={`px-6 py-3 rounded-lg transition-all duration-300 text-white font-semibold ${
                                saveDisabled ? 'bg-net-grey-450 cursor-not-allowed' : 'bg-net-purple-500 hover:bg-net-purple-900'
                            }`}
                        >
                            Save Settings
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ProfileSettingsPage
