import React from 'react'

const NotFoundPage = () => {
    return (
        <div className='flex flex-col items-center justify-center mt-40'>
            <img src='/errors/404.png' width='256' height='256' alt='PNF' className='mb-4' />
            <h1 className='text-4xl font-bold mb-2'>404</h1>
            <p className='text-lg text-net-grey-300'>PAGE NOT FOUND</p>
        </div>
    )
}

export default NotFoundPage
