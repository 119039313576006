import axios from 'axios'
import { Spin } from 'antd'

import toast from 'react-hot-toast'
import { decodeToken } from '@utils/jwt.js'

import { useEffect, useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { AuthContext } from './AuthContext.jsx'
const API_URL = import.meta.env.VITE_BACKEND_URL

const AuthManager = () => {
    const location = useLocation()
    const navigate = useNavigate()

    const { setAuth } = useContext(AuthContext)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const code = params.get('code')
        const state = params.get('state')

        if (!code) {
            console.error('Parametr `code` is required')
            toast.error('Authorization error!')
            setLoading(false)
            return
        }

        const formData = new FormData()
        formData.append('code',  code)

        if (state) {
            formData.append('promocode', state)
        }

        axios.post(`${API_URL}/users/authorize`, formData).then(
            (response) => {
                const { token } = response.data
                const tokenData = decodeToken(token)
                localStorage.setItem('net:token', token)

                axios.get('https://discord.com/api/v10/users/@me', {
                    headers: {
                        'Authorization': `Bearer ${tokenData.aToken}`
                    }

                }).then((response) => {
                    const userData = response.data
                    setAuth({isAuthenticated: true,
                        user: {...userData}, jwt: {...tokenData}})
                    toast.success('Successful authorization')

                    const userBonus = tokenData?.userBonus
                    if (userBonus) {
                        toast.success(`You received a ${userBonus} netcoin bonus`)
                    }

                }).catch((error) => {
                    setAuth({isAuthenticated: false, user: null, jwt: null})
                    console.error('Authentication error', error)
                    toast.error('Authorization error!')
                })
            }
        ).catch((error) => {
            setAuth({isAuthenticated: false, user: null, jwt: null})
            console.error('Authentication error', error)
            toast.error('Authorization error!')

        }).finally(() => {
            setLoading(false)
            navigate('/')
        })
    }, [location.search, navigate, setAuth])

    return (
        <>
            {loading ? <Spin size='large' fullscreen /> : null}
            <h1 className='text-center'>Authorization</h1>
        </>

    )
}

export default AuthManager
