import { jwtDecode } from 'jwt-decode'

export const decodeToken = (token) => {
    try {
        return jwtDecode(token)
    } catch (error) {
        console.error('Incorrect token', error)
        return null
    }
}

export const getTokenDataByKey = (token, key) => {
    const decode = jwtDecode(token)
    if (decode && key in decode) {
        return decode[key]
    }

    return null
}
