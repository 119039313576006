import { useContext } from 'react'
import { StepContext } from '../StepContext'
import { useNavigate } from 'react-router-dom'
import gradients from '@constants/gradients'
import { getIconUrl, createIcon, formatNumber    } from '@utils/guilds.jsx'
import { findGuildById } from '@utils/guilds'
import { getTokenDataByKey } from '@utils/jwt'
import tags from '@constants/tags'
import { FaHashtag } from 'react-icons/fa'
import { LuUsers } from 'react-icons/lu'

const API_URL = import.meta.env.VITE_BACKEND_URL

export default function GuildPersonalization() {
    const navigate = useNavigate()
    const { userGuilds, guildId, guildInfo, setGuildInfo, handleBack } = useContext(StepContext)

    // Получение выбранного сервера по ID
    const guild = findGuildById(userGuilds, guildId)


    const handleSaveCustomization = async () => {
        try {
            // Получение JWT токена из локального хранилища
            const token = localStorage.getItem('net:token')
            if (!token) {
                throw new Error('JWT token is missing')
            }

            // Формирование данных для запроса
            const requestData = {
                id: guildId, // ID гильдии
                name: guild.name, // Имя гильдии (предположим, что оно есть в объекте guild)
                icon: guild.icon,
                owner: getTokenDataByKey(token, 'userId'), // ID владельца гильдии
                inviteLink: guildInfo.inviteLink, // Пригласительная ссылка
                tags: guildInfo.tags, // Теги
                gradient: guildInfo.gradient, // Градиент
                members: guildInfo.members,
                createdAt: guildInfo.createdAt,
                shortDescription: guildInfo.shortDescription, // Краткое описание
                longDescription: guildInfo.longDescription // Длинное описание
            }

            // Отправка POST-запроса
            const response = await fetch(`${API_URL}/guilds/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Добавляем JWT токен в заголовок
                },
                body: JSON.stringify(requestData)
            })

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`)
            }

            // Опционально: получение ответа сервера
            const data = await response.json()
            console.log('Customization saved successfully:', data)
            navigate('/')

        } catch (error) {
            console.error('Error saving customization:', error)
        }
    }

    // Находим выбранный градиент
    const currentGradient = gradients.find(gradient => gradient.id === guildInfo.gradient)
    const guildTags = guildInfo.tags.map(tagId => tags.find(tag => tag.id === tagId))
    const visibleTags = guildTags.slice(0, 3)
    const remainingTags = guildTags.length - 3

    return (
        <div className="bg-net-grey-500 border border-net-grey-450 p-6 rounded-lg shadow-lg">
            <div>
                <h2 className="text-2xl font-bold">Personalization of guild card</h2>
                <p className="font-medium text-net-grey-300">Customize your guild card</p>
            </div>

            <div className="flex space-x-10 mt-6 justify-between">
                <div className="flex flex-col space-y-10 w-72">
                    <div className="space-y-4">
                        <h3 className="text-lg text-white font-medium">Choose the appropriate gradient:</h3>
                        {[0, 1, 2].map((rowIndex) => (
                            <div key={rowIndex} className="flex gap-2">
                                {gradients.slice(rowIndex * 6, rowIndex * 6 + 6).map((gradient) => (
                                    gradient && gradient.colors && (
                                        <label key={gradient.id} className="flex items-center">
                                            <input
                                                type="radio"
                                                value={gradient.id}
                                                className="hidden peer"
                                                checked={guildInfo.gradient === gradient.id}
                                                onChange={() => setGuildInfo((prev) => ({ ...prev, gradient: gradient.id }))}
                                            />
                                            <div
                                                className="w-10 h-10 rounded-lg cursor-pointer transition-colors duration-300 peer-checked:border-2 peer-checked:border-white"
                                                style={{
                                                    background: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(to bottom right, ${gradient.colors[0]}, ${gradient.colors[1]})`,
                                                }}
                                            ></div>
                                        </label>
                                    )
                                ))}
                            </div>
                        ))}

                        <div className='text-white font-medium text-base'>
                            Gradient <span className='text-net-grey-300'>#{parseInt(currentGradient.id, 10) + 1}</span> is selected. Great choice!
                        </div>
                    </div>
                </div>

                <div
                    className="w-[417px] h-[252px] flex items-center justify-center rounded-lg"
                    style={{
                        background: `linear-gradient(to bottom right, ${currentGradient.colors[0]}, ${currentGradient.colors[1]})`,
                    }}>

                    <div className="w-[415px] h-[250px] bg-[#1f222d] relative rounded-lg">
                        <div
                            className="absolute top-0 left-0 right-0 h-[45%] rounded-t-lg border-t-[2px] border-l-[2px] border-r-[2px] border-[#1f222d]"
                            style={{
                                background: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(to bottom right, ${currentGradient.colors[0]}, ${currentGradient.colors[1]})`,
                            }}>

                        </div>

                        {guild.icon ? (
                            <img
                                src={getIconUrl(guild.id, guild.icon)}
                                alt="Avatar"
                                className="absolute top-[45%] left-[20px] w-[75px] h-[75px] rounded-full border-4 border-[#1f222d] transform -translate-y-1/2"/>

                        ) : (
                            <div
                                className="absolute top-[45%] left-[20px] w-[75px] h-[75px] border-4 border-[#1f222d] rounded-full transform -translate-y-1/2 flex items-center justify-center"
                            >
                                {createIcon({
                                    name: guild.name,
                                    width: 'w-[70px]',
                                    height: 'h-[70px]',
                                })}
                            </div>
                        )}
                        <span className="absolute top-[46%] left-[105px] font-bold text-white text-lg">
                            {guild.name || 'Guild Name'}
                        </span>

                        {/* Секция тегов под аватаром и названием */}
                        <ul className='absolute top-[63%] left-[20px] flex gap-2'>
                            {visibleTags.map(tag => (
                                <li key={tag.id} className='flex items-center text-white bg-net-grey-500 p-1 text-sm rounded-lg transition-all duration-300 border border-net-grey-450'>
                                    <FaHashtag className='text-net-purple-500' />
                                    <span className='ml-1'>{tag.name}</span>
                                </li>
                            ))}
                            {remainingTags > 0 && (
                                <li className='flex items-center text-white bg-net-grey-500 p-1 text-sm rounded-lg transition-all duration-300 border border-net-grey-450'>
                                    <FaHashtag className='text-net-purple-500' />
                                    <span className='ml-1'>+{remainingTags} more</span>
                                </li>
                            )}
                        </ul>

                        <div className='absolute bottom-2 left-[20px] right-[20px] flex items-center justify-between'>
                            {/* Количество пользователей и рейтинг */}
                            <div className='flex items-center space-x-6 text-white'>
                                <div className='flex items-center'>
                                    <LuUsers className='text-2xl leading-none'/>
                                    <span className='ml-2 text-lg text-net-grey-300 font-medium'>{formatNumber(guildInfo.members.total)}</span>
                                </div>
                                <div className='flex items-center'>
                                    <i className='fi fi-rr-star text-xl leading-none mt-1'></i>
                                    <span className='ml-2 text-lg text-net-grey-300 font-medium'>0</span>
                                </div>
                            </div>

                            <div className='flex items-center space-x-2 font-medium'>
                                <button
                                    className='px-3 py-3 bg-net-white-100 duration-150 hover:bg-net-white-200 rounded-md leading-none'
                                    type='button'
                                >
                                    <i className='fi fi-rr-share text-lg leading-none'></i>
                                </button>
                                <button className='px-6 py-3 text-lg bg-net-purple-500 hover:bg-net-purple-900 text-white rounded-md leading-none'>
                                    Join
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr className="border-t border-net-grey-450 my-4" />

            <div className="flex justify-end space-x-4 mt-6">
                <button
                    onClick={handleBack}
                    className="bg-net-white-100 text-white text-lg rounded-lg py-3 px-6 duration-150 hover:bg-net-white-200">

                    Back
                </button>
                <button
                    className="px-4 py-2 text-lg rounded-lg shadow-lg bg-net-purple-500 hover:bg-net-purple-900 text-white transition-colors duration-150 ease-in-out"
                    onClick={handleSaveCustomization}>

                    Complete
                </button>
            </div>
        </div>
    )
}
