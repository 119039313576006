import React from 'react'
import { Link } from 'react-router-dom'

import { LuUsers } from 'react-icons/lu'
import { FaHashtag } from 'react-icons/fa'

import tags from '@constants/tags.js'
import { getGradientById } from '@constants/gradients.js'
import { createIcon, formatNumber } from '@utils/guilds.jsx'
import { getVisibleTags, mapTagIdsToTags } from '@utils/tags.js'

const CDN_URL = import.meta.env.VITE_CDN_URL

export const getIconUrl = (guild_id, iconHash) => {
    const extension = iconHash.startsWith('a_') ? 'gif' : 'png'
    return `${CDN_URL}/icons/${guild_id}/${iconHash}.${extension}`
}


function GuildCard({guild, handleShareClick = null}) {
    const gradient = getGradientById(guild.gradient)
    const guildTags = mapTagIdsToTags(guild.tags, tags)

    const remainingTags = guildTags.length - 3
    const visibleTags = getVisibleTags(guildTags, false, 3)

    return (
        <div
            key={guild._id}
            className='w-[417px] h-[252px] flex items-center justify-center rounded-lg'
            style={{
                background: `linear-gradient(to bottom right, ${gradient[0]}, ${gradient[1]})`,
            }}
        >
            <div className='w-[415px] h-[250px] bg-[#1f222d] relative rounded-lg'>
                <div className='absolute top-0 left-0 right-0 h-[45%] rounded-t-lg border-t-[2px] border-l-[2px] border-r-[2px] border-[#1f222d]'
                    style={{ background: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(to bottom right, ${gradient[0]}, ${gradient[1]})` }}>
                </div>
                {guild.icon
                    ? (
                        <img
                            src={getIconUrl(guild._id, guild.icon)}
                            alt='Avatar'
                            className='absolute top-[45%] left-[20px] w-[75px] h-[75px] rounded-full border-4 border-[#1f222d] transform -translate-y-1/2'
                        />
                    ) : (
                        <div
                            className='absolute top-[45%] left-[20px] w-[75px] h-[75px] border-4 border-[#1f222d] rounded-full transform -translate-y-1/2 flex items-center justify-center'
                        >
                            {createIcon({
                                name: guild.name,
                                width: 'w-[70px]',
                                height: 'h-[70px]',
                            })}
                        </div>
                    )
                }

                <Link to={`/guilds/${guild._id}`}>
                    <span className='absolute top-[46%] left-[105px] font-bold text-white text-lg'>{guild.name}</span>
                </Link>

                {/* Секция тегов под аватаром и названием */}
                <ul className='absolute top-[63%] left-[20px] flex gap-2'>
                    {visibleTags.map(tag => (
                        <li key={tag.id} className='flex items-center text-white bg-net-grey-500 p-1 text-sm rounded-lg transition-all duration-300 border border-net-grey-450'>
                            <FaHashtag className='text-net-purple-500' />
                            <span className='ml-1'>{tag.name}</span>
                        </li>
                    ))}
                    {remainingTags > 0 && (
                        <li className='flex items-center text-white bg-net-grey-500 p-1 text-sm rounded-lg transition-all duration-300 border border-net-grey-450'>
                            <FaHashtag className='text-net-purple-500' />
                            <span className='ml-1'>+{remainingTags} more</span>
                        </li>
                    )}
                </ul>

                <div className='absolute bottom-2 left-[20px] right-[20px] flex items-center justify-between'>
                    {/* Количество пользователей и рейтинг */}
                    <div className='flex items-center space-x-6 text-white'>
                        <div className='flex items-center'>
                            <LuUsers className='text-2xl leading-none' />
                            <span className='ml-2 text-lg text-net-grey-300 font-medium'>{formatNumber(guild.members.total)}</span>
                        </div>
                        <div className='flex items-center'>
                            <i className='fi fi-rr-star text-xl leading-none mt-1'></i>
                            <span className='ml-2 text-lg text-net-grey-300 font-medium'>{guild.rating}</span>
                        </div>
                    </div>

                    <div className='flex items-center space-x-2 font-medium'>
                        <button
                            className='px-3 py-3 bg-net-white-100 duration-150 hover:bg-net-white-200 rounded-md leading-none'
                            data-link={guild._id} onClick={handleShareClick}
                        >
                            <i className='fi fi-rr-share text-lg leading-none relative top-[2px]'></i>
                        </button>
                        <a href={guild.invite_link} target='_blank' rel='noreferrer' className='px-6 py-3 text-lg bg-net-purple-500 hover:bg-net-purple-900 text-white rounded-md leading-none'>
                            Join
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GuildCard
