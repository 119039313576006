import { useContext } from 'react'
import { StepContext } from '../StepContext'
import { getIconUrl, createIcon } from '../../../../../utils/guilds.jsx'

const GuildSelection = () => {
    const { userGuilds, handleSelectGuildId } = useContext(StepContext)

    if (!userGuilds || userGuilds.length === 0) {
        return (
            <div className='grid grid-cols-3 gap-8'>
                {Array.from({ length: 6 }).map((_, index) => (
                    <div key={index} className='w-full animate-pulse'>
                        <div 
                            className='relative flex justify-center items-center 
                            rounded-lg bg-net-grey-500 h-40 w-full overflow-hidden'>

                            <div className='absolute inset-0 z-10 bg-net-grey-450 opacity-50'></div>
                            <div className='relative z-20 bg-net-grey-450 w-24 h-24 rounded-full flex-shrink-0'></div>
                        </div>

                        <div className='flex justify-between items-start mt-4'>
                            <div className='mr-3 flex-1'>
                                <div className='bg-net-grey-450 h-6 w-3/4 rounded mb-2.5'></div>
                                <div className='bg-net-grey-450 h-4 w-1/2 rounded'></div>
                            </div>

                            <div className='bg-net-grey-450 h-10 w-24 rounded-lg'></div>
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    return (
        <div className='grid grid-cols-3 gap-8'>
            {userGuilds.map(guild => (
                <div key={guild.id} className='w-full'>
                    <div 
                        className='relative flex justify-center items-center
                        rounded-lg bg-net-grey-500 h-40 w-full overflow-hidden'>

                        {guild.icon ? (
                            <>
                                <div 
                                    className='absolute inset-0 z-10 bg-center 
                                    bg-cover bg-no-repeat scale-125 blur-md opacity-50'
                                    style={{ backgroundImage: `url(${getIconUrl(guild.id, guild.icon)})` }}>
                                </div>
                                <img 
                                    src={getIconUrl(guild.id, guild.icon)} 
                                    alt={guild.name}
                                    className='relative z-20 w-24 h-24 
                                    rounded-full border-2 flex-shrink-0'/>
                            </>
                        ) : (
                            <>
                                <div 
                                    className='absolute inset-0 z-10 flex items-center justify-center
                                    bg-cover bg-no-repeat scale-[2] blur-md opacity-30'>
                                    {createIcon({ name: guild.name })}
                                </div>
                                {createIcon({ name: guild.name })}
                            </>
                        )}
                    </div>

                    <div className='flex justify-between items-start mt-4'>
                        <div className='mr-3 max-w-48'>
                            <h3
                                className='font-medium text-lg leading-6 mb-1.5'
                            >

                                {guild.name}
                            </h3>
                            <div className='text-gray-500'>{guild.owner ? 'Owner' : 'Admin'}</div>
                        </div>

                        <button
                            onClick={() => handleSelectGuildId(guild.id)}
                            className='flex justify-center items-center gap-6 bg-net-white-100
                            rounded-lg px-6 py-3.5 duration-300 hover:bg-net-white-200
                            disabled:cursor-not-allowed disabled:bg-net-white-100
                            disabled:hover:bg-net-white-50'>

                            <span className='whitespace-nowrap text-ellipsis overflow-hidden text-center font-medium block w-full'>
                                Add
                            </span>
                        </button>
                    </div>
                </div>
            ))}
        </div>
    )
}


export default GuildSelection

