import React, { useContext } from 'react'
import Search from './components/Search.jsx'
import { AuthContext } from '@components/auth/AuthContext.jsx'

const HomePage = () => {
    const { auth } = useContext(AuthContext)
    console.log(auth)

    return (
        <div className='max-w-7xl mx-auto'>
            <div className='mt-4 mb-16'>
                <h1
                    className='text-5xl font-bold mb-4'>
                    Guide to the Discord universe
                </h1>
                <span
                    className='text-3xl text-net-grey-300 font-medium'>
                    Discover a world of possibilities with us ^_^
                </span>
            </div>

            <Search/>
        </div>
    )
}

export default HomePage

