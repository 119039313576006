import React, { useContext, Fragment, useState, useEffect } from 'react'
import { Menu, MenuItem, MenuItems, MenuButton } from '@headlessui/react'
import { Link } from 'react-router-dom'
import { CgProfile } from 'react-icons/cg'
import { RxExit } from 'react-icons/rx'
import { IoSettingsOutline } from 'react-icons/io5'
import { RiRobot2Line } from 'react-icons/ri'
import { FiServer } from 'react-icons/fi'
import { AuthContext } from './auth/AuthContext.jsx'
import { getAvatarUrl } from '@utils/avatars.js'
import toast from 'react-hot-toast'

const addMenuLinks = [
    { 
        href: '/bots/add',
        label: 'Add bot', disabled: true,
        icon: <RiRobot2Line className='w-5 h-5 mr-2' />
    },
    { 
        href: '/guilds/add',
        label: 'Add guild', disabled: false,
        icon: <FiServer className='w-5 h-5 mr-2' />
    }
]

const baseMenuLinks = [
    { 
        href: '/partners', label: 'Partners', disabled: false,
        icon: <i className='fi fi-rr-handshake mr-2 leading-none'></i> 
    },
    { 
        href: '/support', label: 'Support', disabled: false,
        icon: <i className='fi fi-rr-interrogation mr-2 leading-none'></i>
    },
    { 
        href: '/bonus', label: 'Bonus', disabled: false,
        icon: <i className='fi fi-rr-piggy-bank mr-2 leading-none'></i>
    },
]

const userMenuLinks = [
    {
        href: '/profile',
        label: 'Profile', disabled: false,
        icon: <CgProfile className='w-5 h-5 mr-2' />
    },
    {
        href: '/settings',
        label: 'Settings', disabled: false,
        icon: <IoSettingsOutline className='w-5 h-5 mr-2' />
    }
]

const menuButtonStyle = `
    flex items-center px-3 py-2.5 text-md text-white 
    data-[focus]:text-white data-[disabled]:opacity-50 
    data-[disabled]:cursor-not-allowed data-[disabled]:text-white 
    font-medium rounded-lg hover:bg-slate-500/10 duration-150
`

const Header = () => {
    const { auth, login, logout } = useContext(AuthContext)
    const [isScrolled, setIsScrolled] = useState(false)
    const [timeLeft, setTimeLeft] = useState(null)

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 10)
        }        
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    // Обновление обратного отсчета сессии, если пользователь авторизован
    useEffect(() => {
        if (auth.isAuthenticated && auth.jwt?.exp) {
            const updateCountdown = () => {
                const now = Date.now()
                const expiry = auth.jwt.exp * 1000 // exp в секундах, умножаем на 1000 для мс
                const diff = expiry - now
                setTimeLeft(diff > 0 ? diff : 0)
            }

            updateCountdown()
            const interval = setInterval(updateCountdown, 1000)
            return () => clearInterval(interval)
        }
    }, [auth.isAuthenticated, auth.jwt?.exp])

    useEffect(() => {
        if (auth.isAuthenticated && timeLeft === 0) {
            logout()
            toast('The session has expired. Authorize again', {icon: '😔'})
        }
    }, [timeLeft, auth.isAuthenticated, logout])

    const formatTime = (ms) => {
        const totalSec = Math.floor(ms / 1000)
        const minutes = String(Math.floor(totalSec / 60)).padStart(2, '0')
        const seconds = String(totalSec % 60).padStart(2, '0')
        return `${minutes}:${seconds}`
    }

    return (
        <header className='sticky top-0 py-4 z-20 bg-gradient-to-b from-net-purple-100 transition-colors duration-300 ease-linear'>
            {/* Наложение с затемнением при прокрутке */}
            <div className={`absolute inset-0 bg-net-grey-500 border-b border-net-grey-450 transition-opacity duration-500 ${isScrolled ? 'opacity-100' : 'opacity-0'}`} />

            <div className='relative max-w-7xl mx-auto flex justify-between items-center'>
                <div className='flex items-center space-x-8'>
                    <Link to='/'>
                        <img src='/logos/netrix-logo.png' alt='Netrix Logo' className='w-12 h-12'/>
                    </Link>

                    <nav className='flex space-x-6 font-medium text-lg'>
                        <Menu as='div' className='relative inline-block text-left'>
                            <MenuButton className='text-white flex items-center hover:text-gray-300 duration-150'>
                                <i className='fi fi-rr-add mr-2 leading-none'></i>
                                Add
                            </MenuButton>

                            <MenuItems
                                transition
                                className='absolute mt-2 w-56 bg-net-grey-500 border 
                                border-net-grey-450 rounded-lg focus:outline-none z-10 
                                origin-top-left transition ease-out data-[closed]:scale-95 
                                data-[closed]:opacity-0'>
                                
                                <div className='p-2'>
                                    {addMenuLinks.map((link) => (
                                        <MenuItem
                                            as={Fragment}
                                            key={link.href}
                                            disabled={link.disabled}>
                                            
                                            <Link
                                                to={link.href}
                                                className={menuButtonStyle}>
                                                {link.icon}
                                                {link.label}
                                            </Link>
                                        </MenuItem>
                                    ))}
                                </div>
                            </MenuItems>
                        </Menu>

                        {baseMenuLinks.map((link) => (
                            <Link 
                                to={link.href}
                                key={link.href}
                                className='text-white flex items-center
                                hover:text-gray-300 duration-150'>
                                {link.icon}
                                {link.label}
                            </Link>
                        ))}
                    </nav>
                </div>

                {/* Профиль пользователя */}
                {auth.isAuthenticated ? (
                    <Menu as='div' className='relative inline-block text-left'>
                        <MenuButton className='flex items-center'>
                            <img 
                                className='h-10 w-10 rounded-full' alt='Profile'
                                src={getAvatarUrl(auth.user.id, auth.user.avatar)}
                            />
                        </MenuButton>

                        <MenuItems
                            transition
                            className='absolute right-0 mt-2 w-56 bg-net-grey-500 border 
                            border-net-grey-450 rounded-lg focus:outline-none z-10 origin-top-left 
                            transition ease-out data-[closed]:scale-95 data-[closed]:opacity-0'>
                            
                            <div className='p-2'>
                                {userMenuLinks.map((link) => (
                                    <MenuItem
                                        as={Fragment}
                                        key={link.href}
                                        disabled={link.disabled}>
                                        
                                        <Link
                                            to={link.href}
                                            className={menuButtonStyle}>
                                            {link.icon}
                                            {link.label}
                                        </Link>
                                    </MenuItem>
                                ))}

                                <hr className='my-2 border-net-grey-450' />

                                {auth.isAuthenticated && timeLeft > 0 && (
                                    <div className='flex items-center justify-between px-3 py-2.5 w-full'>
                                        <div className='flex items-center space-x-2 text-net-grey-300 text-sm uppercase tracking-wide'>
                                            <i className='fi fi-rr-clock relative top-[1px]'></i>
                                            <span>Expires in</span>
                                        </div>
                                        <span className='font-bold text-net-purple-200'>
                                            {formatTime(timeLeft)}
                                        </span>
                                    </div>
                                )}

                                <button
                                    onClick={logout}
                                    className='flex items-center px-3 py-2.5 text-md w-full
                                    text-red-500 data-[focus]:text-red-500 data-[disabled]:opacity-50
                                    data-[disabled]:cursor-not-allowed data-[disabled]:text-red-500
                                    font-medium rounded-lg hover:bg-red-500/10 duration-150'>
                                    <RxExit className='w-5 h-5 mr-2' />
                                    Logout
                                </button>
                            </div>
                        </MenuItems>
                    </Menu>
                ) : (
                    <button 
                        onClick={login}
                        className='px-4 py-2 bg-net-purple-500
                        hover:bg-net-purple-900 duration-150
                        rounded-xl font-medium text-lg'>
                        Login
                    </button>
                )}
            </div>
        </header>
    )
}

export default Header
