import { createContext, useState } from 'react'
import GuildSelection from './steps/GuildSelection'
import GuildBotAddition from './steps/GuildBotAddition'
import GuildInformation from './steps/GuildInformation'
import GuildPersonalization from './steps/GuildPersonalization'

export const StepContext = createContext()

export default function StepProvider({ userGuilds }) {
    const [currentStep, setCurrentStep] = useState(0)

    const [guildId, setGuildId] = useState(null)
    const [botStatus, setBotStatus] = useState(false)

    const initialGuildInfo = {
        shortDescription: '',
        longDescription: '',
        inviteLink: '',
        gradient: '0',
        createdAt: '',
        members: {},
        tags: []
    }

    const [guildInfo, setGuildInfo] = useState(initialGuildInfo)

    const steps = [
        { title: 'Guild Selection', component: GuildSelection },
        { title: 'Service Bot', component: GuildBotAddition },
        { title: 'More Info', component: GuildInformation },
        { title: 'Personalization', component: GuildPersonalization }
    ]

    const handleNext = () => {
        setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1))
    }

    const handleBack = () => {
        setCurrentStep((prev) => Math.max(prev - 1, 0))
    }

    const handleSelectGuildId = (newGuildId) => {
        if (currentStep == 0 && newGuildId !== guildId) {
            setBotStatus(false)
            setGuildInfo(initialGuildInfo)
        }

        setGuildId(newGuildId)
        handleNext()
    }

    const contextValue = {
        userGuilds,

        guildId,
        botStatus,
        setBotStatus,
        guildInfo,
        setGuildInfo,

        handleNext,
        handleBack,

        handleSelectGuildId
    }

    const StepComponent = steps[currentStep].component
    const isStepCompleted = (stepNumber) => stepNumber < currentStep + 1

    return (
        <StepContext.Provider value={contextValue}>
            <div className='flex space-x-10'>
                <div className='w-72'>
                    <ol className='relative flex flex-col items-start'>
                        {steps.map((step, index) => {
                            const stepNumber = index + 1
                            const isCompleted = isStepCompleted(stepNumber)
                            const isCurrent = currentStep === index
                            const isLastStep = index === steps.length - 1

                            return (
                                <li key={stepNumber} className='relative flex items-center mb-20 last:mb-0'>
                                    <div 
                                        className={`flex items-center justify-center w-12 h-12 rounded-full
                                        ${isCompleted || isCurrent ? 'bg-net-purple-500' : 'bg-net-grey-500'}`}>

                                        {isCompleted ? (
                                            <svg 
                                                className='w-5 h-5 text-white' aria-hidden='true' 
                                                xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 12'>

                                                <path 
                                                    stroke='currentColor' strokeLinecap='round' 
                                                    strokeLinejoin='round' strokeWidth='2' d='M1 5.917 5.724 10.5 15 1.5'/>
                                            </svg>
                                        ) : (
                                            <span className='text-white flex items-center justify-center text-xl font-medium'>
                                                {stepNumber}
                                            </span>
                                        )}
                                    </div>

                                    <div className='ms-4'>
                                        <h3 className='font-medium text-lg leading-tight'>{step.title}</h3>
                                    </div>

                                    {!isLastStep && (
                                        <div 
                                            className={`absolute left-[22px] top-16 w-px h-full
                                            ${isCompleted ? 'bg-net-purple-500' : 'bg-net-grey-450'}`}>

                                        </div>
                                    )}
                                </li>
                            )
                        })}
                    </ol>
                </div>

                <div className='flex-1'>
                    <div className='space-y-6'>
                        <StepComponent />
                    </div>
                </div>
            </div>
        </StepContext.Provider>
    )
}

