import axios from 'axios'
import toast from 'react-hot-toast'
import { decodeToken } from '@utils/jwt.js'

import { createContext } from 'react'
import { useState, useEffect } from 'react'

export const AuthContext = createContext()
const AUTH_URL = import.meta.env.VITE_AUTH_URL

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({
        isAuthenticated: false, user: null, jwt: null
    })
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const token = localStorage.getItem('net:token')
        if (!token) {
            setLoading(false) 
            return
        }

        const tokenData = decodeToken(token)
        if (tokenData && tokenData.exp * 1000 > Date.now()) {
            axios.get('https://discord.com/api/v10/users/@me', {
                headers: {
                    'Authorization': `Bearer ${tokenData.aToken}`
                }
            }).then((response) => {
                const userData = response.data
                setAuth({isAuthenticated: true,
                    user: {...userData}, jwt: {...tokenData}})
            }).catch((error) => {
                localStorage.removeItem('net:token')
                setAuth({isAuthenticated: false, user: null, jwt: null})
                console.error('Error fetching user data', error)
            }).finally(() => {
                setLoading(false)
            })
        } else {
            setLoading(false)
            localStorage.removeItem('net:token')
            setAuth({isAuthenticated: false, user: null, jwt: null})
            toast('The session has expired. Authorize again', {icon: '😔'})
        }
    }, [])

    function login() {
        try {
            window.location.href = AUTH_URL
        } catch (error) {
            console.error('Login error', error)
            toast.error('Authorization error!')
        }
    }

    function logout() {
        localStorage.removeItem('net:token')
        setAuth({isAuthenticated: false, user: null, jwt: null})
    }

    if (loading) {
        // Вы можете вернуть индикатор загрузки или null
        return null
    }

    const contextValue = {auth, setAuth, login, logout}

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    )
}
