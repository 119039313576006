import axios from 'axios'
import { Link, useParams, useNavigate } from 'react-router-dom'
import React, { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../../auth/AuthContext.jsx'
import { getAvatarUrl } from '@utils/avatars.js'
import { getIconUrl, createIcon } from '@utils/guilds.jsx'
import SocialLinks from '../ProfilePage/components/SocialLinks.jsx'

const API_URL = import.meta.env.VITE_BACKEND_URL

const UserPage = () => {
    const navigate = useNavigate()
    const { auth } = useContext(AuthContext)
    const { userId } = useParams()
    const [userInfo, setUserInfo] = useState(null)

    console.log(userInfo)

    useEffect(() => {
        if (auth.user && userId === auth.user.id) {
            navigate('/profile')
            return
        }

        const token = localStorage.getItem('net:token') 
        axios.get(
            `${API_URL}/users/${userId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        ).then((response) => {
            setUserInfo(response.data)
        })

    }, [auth.isAuthenticated, auth.jwt])

    if (!userInfo) {
        return null
    }

    const { user } = userInfo

    return (
        <div className='max-w-7xl mx-auto mt-10'>
            {user.is_blocked && (
                <div className="w-full bg-red-600/40 border border-red-600 text-white mb-6 rounded-lg flex items-center justify-center py-4 px-6">
                    <div className="flex items-center">
                        <i className="fi fi-rr-info text-xl mr-2 relative top-[2.5px]"></i>
                        <span className="text-lg font-semibold">
                            This user is blocked
                        </span>
                    </div>
                </div>
            )}

            <div className="flex items-center mb-4">
                <img
                    src={getAvatarUrl(user.id, user.avatar)}
                    alt="Avatar"
                    className="w-16 h-16 rounded-full mr-4"
                />
                <div className="flex-1">
                    <h2 className="text-2xl font-semibold m-0">{user.username}</h2>
                    <span className='text-net-grey-300 font-semibold'>{user.id}</span>
                </div>
                <div className="flex space-x-2">
                    <SocialLinks socialsLinks={user.socials} />

                    {auth.user && auth.user.id == user.id && (
                        <Link
                            to='/settings'
                            className='flex items-center justify-center px-3 py-3 cursor-pointer
                            bg-net-purple-500 hover:bg-net-purple-900 
                            rounded-lg transition-all duration-300'>

                            <i className='fi fi-rr-pencil text-xl leading-none relative top-[2.5px]'></i>
                        </Link>
                    )}
                </div>
            </div>

            <div className='flex space-x-20 items-start'>
                <div className="w-1/4 mt-8 space-y-4">
                    {user.status && (
                        <div className="mb-6 flex items-start">
                            <div className="flex items-center bg-net-purple-500/20 border border-net-purple-500 rounded-lg p-4 space-x-3 w-full max-w-md">
                                <i className="fi fi-rr-comment text-xl relative top-[1px]"></i>
                                <p className="text-white text-base break-words">
                                    {user.status}
                                </p>
                            </div>
                        </div>
                    )}

                    <div className="">
                        <h3 className="text-xl font-semibold mb-2">Details</h3>
                        <ul className="space-y-4">
                            <li>
                                <div className="space-y-1">
                                    <div className="flex items-center">
                                        <i className="fi fi-rr-shield-check text-lg mr-2 relative top-[1px]"></i>
                                        <span className="font-medium">MFA:</span>
                                        <span className="text-net-grey-300 ml-2">
                                            {user.mfa_enabled ? 'True' : 'False'}
                                        </span>
                                    </div>
                                    <div className="flex items-center">
                                        <i className="fi fi-rr-badge-check text-lg mr-2 relative top-[1px]"></i>
                                        <span className="font-medium">Verified:</span>
                                        <span className="text-net-grey-300 ml-2">
                                            {user.verified ? 'True' : 'False'}
                                        </span>
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div className="space-y-1">
                                    <div className="flex items-center">
                                        <i className="fi fi-rr-calendar text-lg mr-2 relative top-[1px]"></i>
                                        <span className="font-medium">Joined:</span>
                                        <span className="text-net-grey-300 ml-2">
                                            {new Date(user.joined_at * 1000).toLocaleDateString()}
                                        </span>
                                    </div>
                                    <div className="flex items-center">
                                        <i className="fi fi-rr-magic-wand text-lg mr-2 relative top-[1px]"></i>
                                        <span className="font-medium">Created:</span>
                                        <span className="text-net-grey-300 ml-2">
                                            {new Date(user.created_at * 1000).toLocaleDateString()}
                                        </span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="w-3/4 mt-10">
                    <div className="flex flex-col space-y-2 bg-net-grey-500 rounded-lg p-4 border border-net-grey-450">
                        <h3 className="text-2xl font-bold">Projects</h3>
                        <div className="grid grid-cols-2 gap-4">
                            {userInfo.guilds.map((guild) => (
                                <Link key={guild.id} to={`/guilds/${guild.id}`}>
                                    <div className="flex items-center space-x-4 bg-net-white-50 hover:bg-net-white-50/60 transition-colors duration-300 p-4 rounded-lg shadow-md">
                                        {guild.icon ? (
                                            <img
                                                src={getIconUrl(guild.id, guild.icon)}
                                                alt={guild.name}
                                                className="w-12 h-12 rounded-full shadow-md"
                                            />
                                        ) : (
                                            createIcon({
                                                name: guild.name,
                                                textSize: 'text-2xl',
                                                width: 'w-12',
                                                height: 'h-12',
                                            })
                                        )}
                                        <div>
                                            <p className="text-white text-lg font-bold">{guild.name}</p>
                                            <p className="text-net-grey-300 text-base">{guild.id}</p>
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserPage
