// gradients.js
const gradients = [
    { id: '0', colors: ['#000000', '#FFFFFF'] },
    { id: '1', colors: ['#000000', '#7B5AFF'] },
    { id: '2', colors: ['#4d474e', '#e51e1b'] },
    { id: '3', colors: ['#d64d61', '#f5da73'] },
    { id: '4', colors: ['#803bf6', '#8ed1fc'] },
    { id: '5', colors: ['#ff8111', '#f5e91f'] },
    { id: '6', colors: ['#3ecc47', '#cdffad'] },
    { id: '7', colors: ['#a18cd1', '#fbc2eb'] },
    { id: '8', colors: ['#52d6fc', '#ff0fff'] },
    { id: '9', colors: ['#3d4052', '#09090c'] },
    { id: '10', colors: ['#ff94fa', '#ffff47'] },
    { id: '11', colors: ['#623fd0', '#35c4fe'] },
    { id: '12', colors: ['#c39aea', '#683df5'] },
    { id: '13', colors: ['#08e817', '#a609d8'] },
    { id: '14', colors: ['#1df39c', '#100ba4'] },
    { id: '15', colors: ['#247ac7', '#aa32a3'] },
    { id: '16', colors: ['#a5fcf0', '#886acc'] },
    { id: '17', colors: ['#ff780a', '#e39bec'] },
]

export default gradients

export const getGradientById = (id) => {
    const gradient = gradients.find(g => g.id === id)
    return gradient ? gradient.colors : gradients[0]['colors']
}
