import React from 'react'

const socials = {
    github: { 
        icon: 'fi fi-brands-github', 
        colors: ['#404040'], 
        baseUrl: 'https://github.com/' 
    },
    gitlab: { 
        icon: 'fi fi-brands-gitlab', 
        colors: ['#e14328', '#fc6b0e', '#ff9e1b'], 
        baseUrl: 'https://gitlab.com/' 
    },
    tiktok: { 
        icon: 'fi fi-brands-tik-tok', 
        colors: ['#25f4ee', '#ff0050'], 
        baseUrl: 'https://tiktok.com/@' 
    },
    twitter: { 
        icon: 'fi fi-brands-twitter-alt', 
        colors: ['#3e4c57'], 
        baseUrl: 'https://twitter.com/' 
    },
    my_site: { 
        icon: 'fi fi-rr-browser', 
        colors: ['#8cc63e'], 
        baseUrl: '' // Пусто, так как это кастомный сайт 
    },
    telegram: { 
        icon: 'fi fi-brands-telegram', 
        colors: ['#0088CC'], 
        baseUrl: 'https://t.me/' 
    },
    instagram: { 
        icon: 'fi fi-brands-instagram', 
        colors: ['#F58529', '#DD2A7B', '#833AB4'], 
        baseUrl: 'https://instagram.com/' 
    },
    discord: { 
        icon: 'fi fi-brands-discord', 
        colors: ['#5865F2'], 
        baseUrl: 'https://discord.com/users/' 
    }
}

const SocialLinks = ({ socialsLinks }) => {
    const hexToRgba = (hex, alpha) => {
        hex = hex.replace('#', '')
        let r = 0, g = 0, b = 0

        if (hex.length === 3) {
            r = parseInt(hex[0] + hex[0], 16)
            g = parseInt(hex[1] + hex[1], 16)
            b = parseInt(hex[2] + hex[2], 16)
        } else if (hex.length === 6) {
            r = parseInt(hex.substring(0, 2), 16)
            g = parseInt(hex.substring(2, 4), 16)
            b = parseInt(hex.substring(4, 6), 16)
        }

        return `rgba(${r}, ${g}, ${b}, ${alpha})`
    }

    return (
        <div className="flex space-x-2">
            {Object.entries(socialsLinks).map(([platform, usernameOrLink]) => {
                if (!usernameOrLink) return null
                const { icon, colors, baseUrl } = socials[platform] || {}
                if (!icon || !colors || baseUrl === undefined) return null

                const link = usernameOrLink.startsWith('https') ? usernameOrLink : `${baseUrl}${usernameOrLink}`

                const transparentColors = colors.map(color => hexToRgba(color, 0.4))

                const backgroundGradient =
                    colors.length > 1
                        ? `linear-gradient(45deg, ${transparentColors.join(', ')})`
                        : transparentColors[0]

                const borderGradient =
                    colors.length > 1
                        ? `linear-gradient(45deg, ${colors.join(', ')})`
                        : colors[0]

                return (
                    <a
                        key={platform}
                        href={link}
                        target="_blank"
                        rel="noreferrer"
                        className="relative flex items-center justify-center px-3 py-3 cursor-pointer rounded-lg transition-all duration-300 overflow-hidden"
                        style={{
                            color: '#fff',
                            background: backgroundGradient,
                        }}
                    >
                        <i className={`${icon} text-xl leading-none text-white relative top-[2.5px] z-10`}></i>

                        <span
                            className="absolute inset-0 rounded-lg"
                            style={{
                                padding: '1px',
                                background: borderGradient,
                                mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                                WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                                maskComposite: 'exclude',
                                WebkitMaskComposite: 'xor',
                                pointerEvents: 'none',
                            }}
                        ></span>
                    </a>
                )
            })}
        </div>
    )
}

export default SocialLinks
