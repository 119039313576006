import axios from 'axios'
import toast from 'react-hot-toast'
import { LuUsers } from 'react-icons/lu'
import React, { useEffect, useState, useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import { getIconUrl, createIcon, formatNumber } from '@utils/guilds.jsx'
import tags from '@constants/tags'
import { RiRobot2Line } from 'react-icons/ri'
import { FaHashtag } from 'react-icons/fa'
import { getAvatarUrl } from '@utils/avatars.js'
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import remarkBreaks from 'remark-breaks'
import ReviewStats from '../../Rating'
import { AuthContext } from '../../auth/AuthContext.jsx'

const API_URL = import.meta.env.VITE_BACKEND_URL

const GuildPage = () => {
    const { auth } = useContext(AuthContext)
    const { guildId } = useParams()

    const [guildData, setGuildData] = useState(null)
    const [timeLeft, setTimeLeft] = useState(null)
    const [upCheckDone, setUpCheckDone] = useState(false) 
    const [lastUpTime, setLastUpTime] = useState(null)

    const components = {
        // eslint-disable-next-line no-unused-vars
        pre: ({ children }) => (
            <h3 className='text-[#d6d9dd] mb-0'>Multiline code is not supported</h3>
        ),
        code({ inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || '')
            return !inline && match ? (
                <h3 className='text-[#d6d9dd] mb-0'>Multiline code is not supported</h3>
            ) : (
                <code className="bg-[#2a2d38] rounded px-1 py-0.5 text-sm border border-[#171923] text-[#c3c9d1]" {...props}>
                    {children}
                </code>
            )
        },
        p: ({ children }) => (
            <p className="text-[#d6d9dd] mb-0">{children}</p>
        ),
        h1: ({ children }) => (
            <h1 className="text-[#ffffffe6] mb-4 text-3xl font-bold">{children}</h1>
        ),
        h2: ({ children }) => (
            <h2 className="text-[#ffffffe6] my-2 text-2xl font-bold">{children}</h2>
        ),
        h3: ({ children }) => (
            <h3 className="text-[#ffffffe6] my-1 text-xl font-bold">{children}</h3>
        ),
        h4: ({ children }) => (
            <h4 className="text-[#ffffffe6] my-0 text-lg font-bold">{children}</h4>
        ),
        h5: ({ children }) => (
            <h5 className="text-[#ffffffe6] my-0 text-base font-bold">{children}</h5>
        ),
        h6: ({ children }) => (
            <h6 className="text-[#ffffffe6] my-0 text-sm font-bold">{children}</h6>
        ),
        ul: ({ children }) => (
            <ul className="list-disc my-0 text-net-grey-300">{children}</ul>
        ),
        ol: ({ children }) => (
            <ol className="list-decimal my-0 text-net-grey-300">{children}</ol>
        ),
        li: ({ children }) => (
            <li className="text-net-grey-300 my-0">{children}</li>
        ),
        a: ({ href, children }) => (
            <a 
                href={href}
                className="text-white hover:underline"
                target="_blank"
                rel="noopener noreferrer"
            >
                {children}
            </a>
        ),
        strong: ({ children }) => (
            <strong className="text-[#ffffffe6]">{children}</strong>
        ),
        blockquote: ({ children }) => (
            <blockquote className="border-l-4 border-net-grey-300 pl-4 my-4 italic text-net-grey-300">
                {children}
            </blockquote>
        ),
    }

    useEffect(() => {
        const token = localStorage.getItem('net:token')
        axios.get(`${API_URL}/guilds/${guildId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                f: 'all',
            },
        })
            .then((response) => {
                const data = response.data
                setGuildData(data)

                let computedTimeLeft = null
                let nextUpTimestamp = null

                if (data.ups && data.ups['guild_id']) {
                    nextUpTimestamp = data.ups['expires'] * 1000
                    const now = Date.now()
                    if (nextUpTimestamp > now) {
                        // Вычисляем оставшееся время
                        const diff = nextUpTimestamp - now
                        const hours = Math.floor(diff / (1000 * 60 * 60))
                        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
                        const seconds = Math.floor((diff % (1000 * 60)) / 1000)
                        computedTimeLeft = `${hours.toString().padStart(2, '0')}:${minutes
                            .toString()
                            .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
                    }
                }

                setTimeLeft(computedTimeLeft)

                // Если есть время до следующего апа – запоминаем его, чтобы вести счетчик
                if (computedTimeLeft && nextUpTimestamp) {
                    setLastUpTime(nextUpTimestamp)
                } else {
                    setLastUpTime(null)
                }

                setUpCheckDone(true)
            })
            .catch((error) => {
                toast.error('Error during data retrieval')
                console.error('Failed getting guild data', error)
                // В случае ошибки тоже ставим флаг
                setTimeLeft(null)
                setLastUpTime(null)
                setUpCheckDone(true)
            })
    }, [guildId])

    useEffect(() => {
        // Запускаем таймер, если у нас есть lastUpTime
        if (!lastUpTime) return
        const interval = setInterval(() => {
            const now = Date.now()
            const diff = lastUpTime - now
            if (diff <= 0) {
                setTimeLeft(null)
                clearInterval(interval)
            } else {
                const hours = Math.floor(diff / (1000 * 60 * 60))
                const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
                const seconds = Math.floor((diff % (1000 * 60)) / 1000)
                setTimeLeft(
                    `${hours.toString().padStart(2, '0')}:${minutes
                        .toString()
                        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
                )
            }
        }, 1000)
        return () => clearInterval(interval)
    }, [lastUpTime])

    const handleUp = async () => {
        try {
            const token = localStorage.getItem('net:token')
            const response = await axios.post(
                `${API_URL}/ups/`,
                {
                    objectId: guildId,
                    objectType: 'guilds'
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            if (response.status === 200) {
                toast.success('Guild up successfully')

                const nextUp = new Date(response.data.next_up_time * 1000).getTime()
                const now = Date.now()
                const diff = nextUp - now
                if (diff > 0) {
                    const hours = Math.floor(diff / (1000 * 60 * 60))
                    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
                    const seconds = Math.floor((diff % (1000 * 60)) / 1000)
                    setTimeLeft(`${hours.toString().padStart(2, '0')}:${minutes
                        .toString()
                        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`)
                    setLastUpTime(nextUp)
                } else {
                    setTimeLeft(null)
                    setLastUpTime(null)
                }

                setGuildData(prev => ({
                    ...prev,
                    guild: {
                        ...prev.guild,
                        up_count: prev.guild.up_count + 1
                    }
                }))
            }
        } catch (error) {
            toast.error(error.response?.data?.detail || 'Error during up')
        }
    }

    if (!guildData) {
        // Скелетон загрузки
        return (
            <div className='max-w-7xl mx-auto mt-10 relative animate-pulse'>
                <div className="flex items-center mb-4">
                    <div className="w-16 h-16 rounded-full bg-net-grey-500 mr-4"></div>
                    <div className="flex-1">
                        <div className="w-1/2 h-5 bg-net-grey-500 mb-2"></div>
                        <div className="w-1/3 h-4 bg-net-grey-500"></div>
                    </div>
                    <div className="flex gap-2">
                        <div className="w-24 h-10 bg-net-grey-500 rounded-lg"></div>
                        <div className="w-20 h-10 bg-net-grey-500 rounded-lg"></div>
                        <div className="w-10 h-10 bg-net-grey-500 rounded-lg"></div>
                    </div>
                </div>

                <div className='flex space-x-20 items-start'>
                    <div className='w-1/4 mt-8 space-y-10'>
                        <div>
                            <div className='w-32 h-5 bg-net-grey-500 mb-4'></div>
                            <ul className="space-y-2">
                                <li className='mb-4'>
                                    <div className="flex items-center space-x-2">
                                        <div className="w-5 h-5 bg-net-grey-500 rounded-full"></div>
                                        <div className="w-24 h-4 bg-net-grey-500"></div>
                                    </div>
                                    <ul className="ml-6 space-y-2 mt-2">
                                        <li className="flex items-center space-x-2">
                                            <div className="w-5 h-5 bg-net-grey-500 rounded-full"></div>
                                            <div className="w-16 h-4 bg-net-grey-500"></div>
                                        </li>
                                        <li className="flex items-center space-x-2">
                                            <div className="w-5 h-5 bg-net-grey-500 rounded-full"></div>
                                            <div className="w-16 h-4 bg-net-grey-500"></div>
                                        </li>
                                    </ul>
                                </li>
                                <li className="flex items-center space-x-2">
                                    <div className="w-5 h-5 bg-net-grey-500 rounded-full"></div>
                                    <div className="w-16 h-4 bg-net-grey-500"></div>
                                </li>
                                <li className="flex items-center space-x-2">
                                    <div className="w-5 h-5 bg-net-grey-500 rounded-full"></div>
                                    <div className="w-16 h-4 bg-net-grey-500"></div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div className='w-32 h-5 bg-net-grey-500 mb-4'></div>
                            <ul className='flex flex-wrap gap-2'>
                                <div className='w-12 h-5 bg-net-grey-500 rounded'></div>
                                <div className='w-12 h-5 bg-net-grey-500 rounded'></div>
                                <div className='w-12 h-5 bg-net-grey-500 rounded'></div>
                            </ul>
                        </div>
                        <div>
                            <div className='w-32 h-5 bg-net-grey-500 mb-4'></div>
                            <div className='flex items-center space-x-4 px-2 py-2'>
                                <div className="w-12 h-12 bg-net-grey-500 rounded-full"></div>
                                <div>
                                    <div className='w-24 h-4 bg-net-grey-500 mb-2'></div>
                                    <div className='w-16 h-3 bg-net-grey-500'></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='w-3/4 mt-10 space-y-4'>
                        <div className='flex flex-col space-y-2 bg-net-grey-500 rounded-lg p-4 border border-net-grey-450'>
                            <div className='w-32 h-5 bg-net-grey-600 mb-4'></div>
                            <div className="text-net-grey-300 overflow-auto min-h-[200px] max-h-[1200px] space-y-2">
                                <div className='w-full h-4 bg-net-grey-600 rounded'></div>
                                <div className='w-3/4 h-4 bg-net-grey-600 rounded'></div>
                                <div className='w-1/2 h-4 bg-net-grey-600 rounded'></div>
                                <div className='w-full h-4 bg-net-grey-600 rounded'></div>
                                <div className='w-2/3 h-4 bg-net-grey-600 rounded'></div>
                            </div>
                        </div>
                        <div className='w-full bg-net-grey-500 rounded-lg p-4 border border-net-grey-450 h-48'></div>
                    </div>
                </div>
            </div>
        )
    }

    const guildTags = guildData.guild?.tags?.map(tagId => tags.find(tag => tag.id === tagId)) || []

    const canUp = upCheckDone && timeLeft === null

    return (
        <div className='max-w-7xl mx-auto mt-10 relative'>
            {guildData.guild.hidden && (
                <div className="w-full bg-net-grey-500 border border-net-grey-450 text-white mb-6 rounded-lg flex items-center justify-center py-4 px-6">
                    <div className="flex items-center">
                        <i className="fi fi-rr-info text-xl mr-2 relative top-[2.5px]"></i>
                        <span className="text-lg font-semibold">
                            This guild is hidden
                        </span>
                    </div>
                </div>
            )}

            <div className="flex items-center mb-4">
                {guildData.guild.icon 
                    ? (
                        <img
                            src={getIconUrl(guildData.guild.id, guildData.guild.icon)}
                            alt="Avatar"
                            className="w-16 h-16 rounded-full mr-4"
                        />
                    ) : (
                        createIcon({
                            name: guildData.guild.name,
                            width: 'w-[64px]',
                            height: 'h-[64px]',
                            textSize: 'text-3xl',
                            classes: 'mr-4'
                        })
                    )
                }
                <div className="flex-1">
                    <h2 className="text-2xl font-semibold m-0">{guildData.guild.name}</h2>
                    <span className='text-net-grey-300 font-semibold'>{guildData.guild.id}</span>
                </div>
                <div className="flex space-x-2">
                    <button
                        onClick={handleUp}
                        disabled={!canUp || !auth.jwt}
                        className='flex justify-center items-center bg-net-white-100
                        rounded-lg px-6 py-2 duration-300 hover:bg-net-white-200
                        disabled:cursor-not-allowed disabled:bg-net-white-100
                        disabled:hover:bg-net-white-100 font-medium w-24'
                    >
                        {upCheckDone ? (
                            timeLeft ? (
                                <span>{timeLeft}</span>
                            ) : (
                                <>Up <span className='text-net-grey-300 ml-1'>({guildData.guild.up_count})</span></>
                            )
                        ) : (
                            <span>...</span>
                        )}
                    </button>

                    <a
                        href={guildData.guild.invite_link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className='px-4 py-2 text-lg text-white rounded-lg
                        bg-net-purple-500 hover:bg-net-purple-900 font-medium
                        transition-colors duration-300 ease-in-out
                        disabled:opacity-50 disabled:cursor-not-allowed'
                    >
                        Join
                    </a>

                    {auth.user && auth.user.id === guildData.guild.owner.id && (
                        <Link
                            to={`/guilds/${guildId}/edit`}
                            className='flex items-center justify-center px-4 py-2 cursor-pointer
                            bg-net-purple-500 hover:bg-net-purple-900 
                            rounded-lg transition-all duration-300'
                        >
                            <i className='fi fi-rr-pencil text-xl leading-none relative top-[2.5px]'></i>
                        </Link>
                    )}
                </div>
            </div>

            <div className='flex space-x-20 items-start'>
                <div className='w-1/4 mt-8 space-y-10'>
                    <div>
                        <h3 className='text-xl font-semibold mb-2'>Details</h3>
                        <ul className="space-y-2">
                            <li className='mb-4'>
                                <div className="flex items-center">
                                    <LuUsers className="text-lg mr-2" />
                                    <span>Members:</span>
                                    <span className="text-net-grey-300 ml-2">{formatNumber(guildData.guild.members.total)}</span>
                                </div>
                                <ul className="ml-6 space-y-2 mt-2">
                                    <li className="flex items-center">
                                        <LuUsers className="text-lg mr-2" />
                                        <span>People:</span>
                                        <span className="text-net-grey-300 ml-2">{formatNumber(guildData.guild.members.humans)}</span>
                                    </li>
                                    <li className="flex items-center">
                                        <RiRobot2Line className="text-lg mr-2" />
                                        <span>Bots:</span>
                                        <span className="text-net-grey-300 ml-2">{formatNumber(guildData.guild.members.bots)}</span>
                                    </li>
                                </ul>
                            </li>

                            <li className="flex items-center">
                                <i className="fi fi-rr-calendar text-lg mr-2"></i>
                                <span>Added:</span>
                                <span className="text-net-grey-300 ml-2">{new Date(guildData.guild.added_at).toLocaleDateString()}</span>
                            </li>
                            <li className="flex items-center">
                                <i className="fi fi-rr-magic-wand text-lg mr-2"></i>
                                <span>Created:</span>
                                <span className="text-net-grey-300 ml-2">{new Date(guildData.guild.created_at).toLocaleDateString()}</span>
                            </li>
                        </ul>
                    </div>

                    <div>
                        <h3 className='text-xl font-semibold mb-2'>Categories</h3>
                        <ul className='flex flex-wrap gap-2'>
                            {guildTags.map(tag => (
                                <li key={tag.id} className='flex items-center text-white bg-net-grey-500 p-1 text-sm rounded-lg transition-all duration-300 border border-net-grey-450'>
                                    <FaHashtag className='text-net-purple-500' />
                                    <span className='ml-1'>{tag.name}</span>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div>
                        <h3 className='text-xl font-semibold mb-2'>Admins</h3>
                        <a 
                            href={`/users/${guildData.guild.owner.id}`}
                            className='flex items-center space-x-4 px-2 py-2
                            font-medium rounded-lg hover:bg-slate-500/10
                            duration-300 transition-colors'
                        >
                            <img
                                src={getAvatarUrl(guildData.guild.owner.id, guildData.guild.owner.avatar)}
                                alt="Owner Avatar"
                                className="w-12 h-12 rounded-full"
                            />
                            <div>
                                <p className='text-lg'>{guildData.guild.owner.username}</p>
                                <p className='text-net-grey-300 text-sm'>{guildData.guild.owner.id}</p>
                            </div>
                        </a>
                    </div>
                </div>

                <div className='w-3/4 mt-10'>
                    <div className='flex flex-col space-y-2 bg-net-grey-500 rounded-lg p-4 border border-net-grey-450'>
                        <h3 className='text-2xl font-bold'>Description</h3>
                        <div className="text-net-grey-300 overflow-auto min-h-[200px] max-h-[1200px]">
                            <Markdown
                                remarkPlugins={[remarkGfm, remarkBreaks]}
                                components={components}
                                className='max-w-max prose prose-code:whitespace-pre-wrap prose-code:px-[4px] 
                                prose-code:py-[2px] prose-code:before:content-none prose-code:after:content-none'
                            >
                                {guildData.guild.long_description}
                            </Markdown>
                        </div>
                    </div>

                    <div className='w-full mt-4'>
                        <ReviewStats
                            ratings={guildData.ratings}
                            reviews={guildData.reviews}
                            type='guilds'
                            object_id={guildData.guild.id}
                            author={auth.user}
                            currentUserReview={guildData.reviews[0]}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GuildPage
