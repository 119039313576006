import * as Sentry from '@sentry/react'
import { createRoot } from 'react-dom/client'
import App from './App.jsx'
import './index.css'

Sentry.init({
    dsn: 'https://a47fff67e81ec8187423261c4bc580df@o4508128003883008.ingest.de.sentry.io/4508339512672336',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', '127.0.0.1', /^https:\/\/api\.netrix\.fun\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

createRoot(document.getElementById('root')).render(
    <App />
)
