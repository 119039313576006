import axios from 'axios'
import { Skeleton } from 'antd'
import toast from 'react-hot-toast'
import debounce from 'lodash.debounce'
import React, { useState, useEffect, useCallback } from 'react'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'

import GuildCard from './GuildCard.jsx'
import { FaHashtag } from 'react-icons/fa'

import tags from '@constants/tags'
import { handleTagChange, getVisibleTags } from '@utils/tags'

const CDN_URL = import.meta.env.VITE_CDN_URL
const API_URL = import.meta.env.VITE_BACKEND_URL

const SORT_BY = [
    {
        id: 'popularity',
        name: 'Popularity',
        disabled: false,
        icon: <i className='fi fi-rr-fire-flame-curved mr-2 relative top-[2px]'></i>
    },
    {
        id: 'rating',
        name: 'Rating',
        disabled: false,
        icon: <i className='fi fi-rr-social-network mr-2 relative top-[2px]'></i>
    },
    {
        id: 'members',
        name: 'Members',
        disabled: false,
        icon: <i className='fi fi-rr-chart-histogram mr-2 relative top-[2px]'></i>
    },
    {
        id: 'added_at',
        name: 'Latest',
        disabled: false,
        icon: <i className='fi fi-rr-galaxy-star mr-2 relative top-[2px]'></i>
    },
    {
        id: 'created_at',
        name: 'Older',
        disabled: false,
        icon: <i className='fi fi-rr-magic-wand mr-2 relative top-[2px]'></i>
    }
]

const menuButtonStyle = `
    flex items-center px-3 py-2.5 text-md text-white 
    data-[focus]:text-white data-[disabled]:opacity-50 
    data-[disabled]:cursor-not-allowed data-[disabled]:text-white 
    font-medium rounded-lg hover:bg-slate-500/10 duration-150 cursor-pointer
`

export const getIconUrl = (guild_id, iconHash) => {
    const extension = iconHash.startsWith('a_') ? 'gif' : 'png'
    return `${CDN_URL}/icons/${guild_id}/${iconHash}.${extension}`
}

const Search = () => {
    const [searchText, setSearchText] = useState('')
    const [selectedTags, setSelectedTags] = useState([])
    const [selectedSort, setSelectedSort] = useState(SORT_BY[0].id)

    const [guilds, setGuilds] = useState([])
    const [loading, setLoading] = useState(true)

    const [showAllTags, setShowAllTags] = useState(false)
    const displayedTags = getVisibleTags(tags, showAllTags)

    const handleShareClick = (event) => {
        const guildId = event.currentTarget.getAttribute('data-link')
        
        if (guildId) {
            const fullLink = `${window.location.origin}/guilds/${guildId}`
            navigator.clipboard.writeText(fullLink)
                .then(() => {
                    toast.success('Link copied to clipboard')
                })
                .catch(() => {
                    toast.error('Failed to copy link')
                })
        } else {
            toast.error('No link found')
        }
    }

    const handleSearch = useCallback(
        debounce(() => {
            setLoading(true)

            const filters = {
                text: searchText,
                tags: selectedTags,
                sort_by: selectedSort,
            }
    
            axios.post(`${API_URL}/guilds/search`, {
                page: 1,
                page_size: 18,
                filters: filters,
            })
                .then((response) => {
                    if (response.data && response.data.guilds) {
                        if (response.data.guilds.length === 0) {
                            toast.error('Nothing found on your request')
                        }
                        setGuilds(response.data.guilds)
                    }
                })
                .catch((error) => {
                    console.error('Error while performing a search', error)
                    toast.error('Error while performing a search')
                })
                .finally(() => {
                    setLoading(false)
                })
        }, 300),
        [searchText, selectedTags, selectedSort]
    )    

    // const handleSearch = useCallback(
    //     debounce(() => {
    //         const filters = {
    //             text: searchText,
    //             tags: selectedTags,
    //             sort_by: selectedSort.id
    //         }
    
    //         axios.post(`${API_URL}/guilds/search`, {
    //             page: 1,
    //             page_size: 18,
    //             filters: filters
    //         }).then((response) => {
    //             if (response.data && response.data.guilds) {
    //                 if (response.data.guilds.length === 0) {
    //                     toast.error('Nothing found on your request')
    //                 }
    //                 setGuilds(response.data.guilds)
    //             }
    //         }).catch((error) => {
    //             console.error('Error while performing a search', error)
    //             toast.error('Error while performing a search')
    //         }).finally(() => {
    //             setLoading(false)
    //         })
    //     }, 300),
    //     [searchText, selectedTags]
    // )

    useEffect(() => {
        handleSearch()
        // Очистка debounce при размонтировании компонента
        return () => {
            handleSearch.cancel()
        }
    }, [searchText, selectedTags, selectedSort])

    return (
        <>
            <div className='flex space-x-2 justify-between text-white'>
                <div
                    className='flex flex-grow space-x-2 border border-net-grey-450
                    bg-net-grey-500 focus-within:bg-slate-500/15 rounded-lg p-2
                    focus-within:border-slate-500/35 duration-300'>

                    <div className='flex items-center pointer-events-none mx-1'>
                        <svg
                            className='w-4 h-4 text-gray-500' aria-hidden='true'
                            xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'>

                            <path
                                stroke='currentColor' strokeLinecap='round' strokeLinejoin='round'
                                strokeWidth='2' d='m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z'/>
                        </svg>
                    </div>

                    <input
                        type='text'
                        placeholder='Поиск...'
                        value={searchText}
                        onChange={(e) => {setSearchText(e.target.value)}}
                        className='bg-transparent flex-grow outline-none focus:bg-transparent duration-300'/>
                </div>

                {/* Существующий код поиска */}
                <div className="relative">
                    <Listbox value={selectedSort} onChange={setSelectedSort}>
                        <div className="relative">
                            <ListboxButton className="w-36 px-4 py-2 flex items-center bg-net-grey-500 border border-net-grey-450 duration-150 rounded-lg font-medium text-lg">
                                {SORT_BY.find(option => option.id === selectedSort)?.icon}
                                {SORT_BY.find(option => option.id === selectedSort)?.name || 'Выберите сортировку'}
                            </ListboxButton>
                            <ListboxOptions transition className="absolute mt-1 p-2 w-full transition-all ease-out data-[closed]:scale-95 data-[closed]:opacity-0 bg-net-grey-500 rounded-md shadow-lg z-10">
                                {SORT_BY.map((option) => (
                                    <ListboxOption
                                        key={option.id}
                                        value={option.id}
                                        disabled={option.disabled}
                                        className={menuButtonStyle}
                                    >
                                        {option.icon}
                                        {option.name}
                                    </ListboxOption>
                                ))}
                            </ListboxOptions>
                        </div>
                    </Listbox>
                </div>
            </div>

            <ul className='flex flex-wrap gap-2 text-white mt-2'>
                {displayedTags.map((tag) => (
                    <li key={tag.id}>
                        <input 
                            id={tag.name} name={tag.name} type='checkbox' className='hidden peer' 
                            checked={selectedTags.includes(tag.id)} onChange={() => {
                                const updatedTags = handleTagChange(selectedTags, tag.id)
                                setSelectedTags(updatedTags)
                            }}/>
                        <label 
                            htmlFor={tag.name} className={
                                `flex items-center p-1.5 cursor-pointer bg-net-grey-500 
                                peer-first:border-net-grey-450 hover:bg-net-grey-400 
                                rounded-lg transition-all duration-300 peer-checked:border-net-purple-500 
                                peer-checked:bg-net-purple-300 peer-checked:hover:bg-net-purple-400 border border-transparent`}>
                            
                            <FaHashtag className='text-net-purple-500 relative top-[1px]' />
                            <span className='ml-1'>{tag.name}</span>
                        </label>
                    </li>
                ))}

                <li>
                    <button 
                        onClick={() => setShowAllTags(!showAllTags)} 
                        className='flex items-center p-1.5 cursor-pointer duration-300
                        bg-net-grey-500 hover:bg-net-grey-400 rounded-lg border border-net-grey-450'>

                        <i className={
                            `${showAllTags 
                                ? 'fi fi-rr-apps-delete' 
                                : 'fi fi-rr-apps-add'} text-lg leading-none`}></i>

                        <span className='ml-2'>{showAllTags ? 'Less tags' : 'More tags'}</span>
                    </button>
                </li>

                {selectedTags.length > 0 && (
                    <li>
                        <button
                            onClick={() => setSelectedTags([])}
                            className='flex items-center p-1.5 cursor-pointer duration-300
                            rounded-lg bg-red-500/30 hover:bg-red-500/40 border border-red-500'>

                            <i className='fi fi-rr-trash-list text-lg leading-none'></i>
                            <span className='ml-2'>Reset tags</span>
                        </button>
                    </li>
                )}
            </ul>

            {loading ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 mt-4">
                    {Array.from({ length: 6 }).map((_, index) => (
                        <Skeleton.Node 
                            key={index} className='bg-net-grey-500 rounded-lg'
                            active={true} shape='square' style={{width: '100%', height: '250px'}}/>
                    ))}
                </div>
            ) : (
                <div className='grid grid-cols-3 gap-8 mt-4'>
                    {guilds.length > 0 ? (
                        guilds.map((guild) => (
                            <GuildCard key={guild._id} guild={guild} handleShareClick={handleShareClick} />
                        ))
                    ) : (
                        <div className='col-span-3 text-center text-white'>
                            <p className='text-lg font-medium'>Nothing was found🥲</p>
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

export default Search
