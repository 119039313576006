const discord_cdn = 'https://cdn.discordapp.com'

export const getIconUrl = (guild_id, iconHash) => {
    const extension = iconHash.startsWith('a_') ? '.gif' : '.png'
    return `${discord_cdn}/icons/${guild_id}/${iconHash}${extension}`
}

export const findGuildById = (userGuilds, guildId) => {
    return userGuilds.find(guild => guild.id === guildId)
}

export const updateGuildInfo = (guildInfo, updates) => {
    return {
        ...guildInfo,
        ...updates,
    }
}

export const formatNumber = (num) => {
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'
    } else if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'k'
    }
    return num.toString()
}

export const createIcon = ({ 
    name, 
    textSize = 'text-4xl', 
    width = 'w-24', 
    height = 'h-24',
    classes = ''
}) => {
    // Получение инициалов: первые буквы первых двух слов, если есть
    const initials = name.split(' ').slice(0, 2).map(word => word[0]).join('').toUpperCase()

    return (
        <div 
            className={`${width} ${height} ${textSize} ${classes} rounded-full flex items-center justify-center bg-net-purple-500`} 
            style={{
                color: 'white', 
                fontWeight: '500'
            }}
        >
            {initials}
        </div>
    )
}
