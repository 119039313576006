import { useEffect } from 'react'
import toast, { Toaster, useToasterStore } from 'react-hot-toast'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import Header from '@components/Header.jsx'
import Footer from '@components/Footer.jsx'

import AuthManager from '@components/auth/AuthManager.jsx'
import { AuthProvider } from '@components/auth/AuthContext.jsx'

import HomePage from '@pages/HomePage/HomePage.jsx'
import ProfilePage from '@pages/ProfilePage/ProfilePage.jsx'
import UserPage from '@pages/UserPage/UserPage.jsx'
import ProfileEditPage from '@pages/ProfileEditPage/ProfileEditPage.jsx'

import AddGuildPage from '@pages/AddGuildPage/AddGuildPage.jsx'
import GuildPage from '@pages/GuildPage/GuildPage.jsx'
import EditGuildPage from '@pages/EditGuildPage/EditGuildPage.jsx'

import NotFoundPage from '@pages/NotFoundPage/NotFoundPage.jsx'


const ToasterComponent = ({ maxCount = 3 }) => {
    const { toasts } = useToasterStore()

    useEffect(() => {
        toasts
            .filter((t) => t.visible)
            .filter((_, i) => i >= maxCount)
            .forEach((t) => toast.dismiss(t.id))
    }, [toasts, maxCount])

    return (
        <Toaster
            toastOptions={{
                duration: 3000,
                style: {
                    background: '#292c3a',
                    color: '#fff',
                    border: '1px solid #3c434d',
                    alignItems: 'center',
                },
            }}
        />
    )
}

function App() {
    return (
        <AuthProvider>
            <Router>
                <div className='bg-net-grey-900 min-h-screen flex flex-col text-white select-none'>
                    <Header />

                    <main className='flex-grow'>
                        <Routes>
                            <Route>
                                <Route path='/' element={<HomePage />} />
                                <Route path='/auth' element={<AuthManager />} />
                                <Route path='/profile' element={<ProfilePage />} />
                                <Route path='/settings' element={<ProfileEditPage />} />

                                <Route path='/users/:userId' element={<UserPage />} />

                                <Route path='/guilds/add' element={<AddGuildPage />} />
                                <Route path='/guilds/:guildId' element={<GuildPage />} />
                                <Route path='/guilds/:guildId/edit' element={<EditGuildPage />} />

                                <Route path='*' element={<NotFoundPage />} />
                            </Route>
                        </Routes>
                    </main>

                    <Footer />

                    <ToasterComponent maxCount={3} />
                </div>
            </Router>
        </AuthProvider>
    )
}

export default App
