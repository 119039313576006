/* eslint-disable indent */
import axios from 'axios'
import { useParams, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { FaHashtag } from 'react-icons/fa'
import { useForm } from 'react-hook-form'
import tags from '@constants/tags'
import toast from 'react-hot-toast'
import { handleTagChange, getVisibleTags } from '@utils/tags'
import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from '@headlessui/react'
import gradients from '@constants/gradients'
import { getIconUrl, createIcon, formatNumber } from '@utils/guilds.jsx'

const API_URL = import.meta.env.VITE_BACKEND_URL

const menuButtonStyle = 
`flex items-center px-3 py-2.5 text-md text-white 
 data-[focus]:text-white data-[disabled]:opacity-50 
 data-[disabled]:cursor-not-allowed data-[disabled]:text-white 
 font-medium rounded-lg hover:bg-slate-500/10 duration-150 cursor-pointer`

export default function EditGuildInformation() {
    const navigate = useNavigate()
    const { register, formState, setValue, handleSubmit, reset, watch } = useForm({
        mode: 'onChange',
        defaultValues: {
            shortDescription: '',
            longDescription: '',
            inviteLink: '',
            hidden: false,
            tags: [],
            gradient: 0,
        }
    })

    const [guildInfo, setGuildInfo] = useState({
        shortDescription: '',
        longDescription: '',
        inviteLink: '',
        hidden: null,
        tags: [],
        gradient: 0,
        members: { total: 0 },
        icon: '',
        name: ''
    })

    const [initialValues, setInitialValues] = useState(null)
    const [guild, setGuild] = useState(null)
    const formErrors = formState.errors
    const isFormValid = formState.isValid
    const isDirty = formState.isDirty

    const defaultClasses = 'border-net-grey-450 bg-transparent focus:bg-slate-500/10'
    const errorClasses = 'border-red-500 bg-red-500/10 focus:border-red-500 focus:bg-red-500/10'

    const shortDescriptionError = formErrors.shortDescription?.message
    const longDescriptionError = formErrors.longDescription?.message
    const inviteLinkError = formErrors.inviteLink?.message

    const [forbidden, setForbidden] = useState(false)
    const [loading, setLoading] = useState(true)
    const [shortDescLength, setShortDescLength] = useState(0)
    const [longDescLength, setLongDescLength] = useState(0)

    const { guildId } = useParams()

    const visibilityOptions = [
        { id: 1, name: 'Visible', value: false },
        { id: 2, name: 'Hidden', value: true },
    ]

    const [activeTab, setActiveTab] = useState('information')
    const [viewTab, setViewTab] = useState('information')
    const [selectedVisibility, setSelectedVisibility] = useState(null)
    const [showContent, setShowContent] = useState(true)

    useEffect(() => {
        const fetchGuildData = async () => {
            try {
                const token = localStorage.getItem('net:token')
                const response = await axios.get(
                    `${API_URL}/guilds/${guildId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`
                        },
                        params: {
                            f: 'edit',
                        },
                    }
                )

                const guildData = response.data || {}
                setGuild(guildData)

                const loadedValues = {
                    shortDescription: guildData.short_description || '',
                    longDescription: guildData.long_description || '',
                    inviteLink: guildData.invite_link || '',
                    hidden: guildData.hidden || false,
                    tags: guildData.tags || [],
                    gradient: guildData.gradient || 0
                }

                setGuildInfo((prev) => ({
                    ...prev,
                    hidden: loadedValues.hidden,
                    tags: loadedValues.tags,
                    gradient: loadedValues.gradient,
                    shortDescription: loadedValues.shortDescription,
                    longDescription: loadedValues.longDescription,
                    inviteLink: loadedValues.inviteLink,
                    members: guildData.members || { total: 0 },
                    icon: guildData.icon,
                    name: guildData.name
                }))

                const initialVisibility = visibilityOptions.find(option => option.value === guildData.hidden)
                setSelectedVisibility(initialVisibility || visibilityOptions[0])

                setShortDescLength(loadedValues.shortDescription.length)
                setLongDescLength(loadedValues.longDescription.length)

                setInitialValues(loadedValues)
                reset(loadedValues)
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    setForbidden(true)
                    return
                }
                console.error('Failed to fetch guild data:', error)
            } finally {
                setLoading(false)
            }
        }

        fetchGuildData()
    }, [guildId, reset])

    const handleCheckboxChange = (id) => {
        const updatedTags = handleTagChange(watch('tags') || [], id)
        setGuildInfo((prev) => ({ ...prev, tags: updatedTags }))
        setValue('tags', updatedTags, { shouldDirty: true })
    }

    const onSave = async (formData) => {
        try {
            const token = localStorage.getItem('net:token')
    
            const dataToSend = {
                inviteLink: formData.inviteLink,
                hidden: formData.hidden,
                tags: formData.tags,
                gradient: formData.gradient,
                shortDescription: formData.shortDescription,
                longDescription: formData.longDescription,
            }
    
            await axios.put(
                `${API_URL}/guilds/${guildId}`,
                dataToSend,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )

            toast.success('Guild updated successfully')
            navigate('/profile')

            setInitialValues(dataToSend)
            reset(dataToSend)
        } catch (error) {
            console.error('Failed to update guild:', error)
            toast.error('Failed to update guild')
        }
    }

    const handleDelete = async () => {
        if(!confirm('Are you sure you want to delete this guild? This action is irreversible.')) return
        try {
            const token = localStorage.getItem('net:token')
            await axios.delete(
                `${API_URL}/guilds/${guildId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
            toast.success('Guild deleted successfully')
            navigate('/profile')
        } catch (error) {
            console.error('Failed to delete guild:', error)
            toast.error('Failed to delete guild')
        }
    }

    if (loading) {
        return (
            <div className='max-w-7xl mx-auto mt-10 relative animate-pulse'>
                {/* Кнопка возврата */}
                <div className='mb-4 w-36 h-10 bg-net-grey-500 rounded-lg'></div>
    
                <div className='bg-net-grey-500 border border-net-grey-450 p-4 rounded-lg'>
                    {/* Заголовок */}
                    <div className='w-1/2 h-6 bg-net-grey-600 mb-4 rounded'></div>
    
                    <form>
                        <div className='flex w-full'>
                            {/* Вертикальные табы слева */}
                            <div className='flex flex-col w-48 border-r border-net-grey-450 pr-4 shrink-0 space-y-2'>
                                <div className='w-full h-10 bg-net-grey-600 rounded-lg'></div>
                                <div className='w-full h-10 bg-net-grey-600 rounded-lg'></div>
                            </div>
    
                            <div className='pl-10 flex-grow min-w-[800px] space-y-10'>
                                {/* Визуально повторяем структуру вкладки Information */}
                                <div className='space-y-10'>
                                    {/* Visibility */}
                                    <div className='flex justify-start items-start space-x-10'>
                                        <div className='w-52 h-5 bg-net-grey-600 rounded'></div>
                                        <div className='flex-grow h-10 bg-net-grey-600 rounded'></div>
                                    </div>
    
                                    {/* Short Description */}
                                    <div className='flex justify-start items-start space-x-10'>
                                        <div className='w-52 h-5 bg-net-grey-600 rounded'></div>
                                        <div className='flex-grow h-10 bg-net-grey-600 rounded'></div>
                                    </div>
    
                                    {/* Long Description */}
                                    <div className='flex justify-start items-start space-x-10'>
                                        <div className='w-52 h-5 bg-net-grey-600 rounded'></div>
                                        <div className='flex-grow h-48 bg-net-grey-600 rounded'></div>
                                    </div>
    
                                    {/* Invite Link */}
                                    <div className='flex justify-start items-start space-x-10'>
                                        <div className='w-52 h-5 bg-net-grey-600 rounded'></div>
                                        <div className='flex-grow h-10 bg-net-grey-600 rounded'></div>
                                    </div>
    
                                    {/* Tags */}
                                    <div className='flex justify-start items-start space-x-10'>
                                        <div className='w-52 h-5 bg-net-grey-600 rounded'></div>
                                        <div className='flex-grow h-10 bg-net-grey-600 rounded'></div>
                                    </div>
    
                                    <hr className="border-t border-net-grey-450" />
    
                                    {/* Delete guild */}
                                    <div className='flex items-center justify-between h-10'>
                                        <div className='w-1/2 h-5 bg-net-grey-600 rounded'></div>
                                        <div className='w-20 h-10 bg-net-grey-600 rounded-lg'></div>
                                    </div>
    
                                    <hr className="border-t border-net-grey-450" />
    
                                    {/* Reset & Save Changes */}
                                    <div className='flex justify-end space-x-4 mt-6'>
                                        <div className='w-20 h-10 bg-net-grey-600 rounded-lg'></div>
                                        <div className='w-24 h-10 bg-net-grey-600 rounded-lg'></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }    

    if (forbidden) {
        return (
            <div className='flex flex-col items-center justify-center mt-40'>
                <img src='/errors/404.png' width='256' height='256' alt='PNF' className='mb-4' />
                <h1 className='text-4xl font-bold mb-2'>403</h1>
                <p className='text-lg text-net-grey-300'>FORBIDDEN</p>
            </div>
        )
    }

    const currentGradient = gradients.find((g) => g.id === watch('gradient')) || gradients[0]

    const switchTab = (tab) => {
        if (tab === activeTab) return
        setActiveTab(tab)
        setShowContent(false)
    }

    const handleAfterLeave = () => {
        setViewTab(activeTab)
        setShowContent(true)
    }

    const handleVisibilityChange = (option) => {
        setSelectedVisibility(option)
        setValue('hidden', option.value, { shouldDirty: true })
        setGuildInfo((prev) => ({ ...prev, hidden: option.value }))
    }

    const handleGradientChange = (gradientId) => {
        setValue('gradient', gradientId, { shouldDirty: true })
        setGuildInfo((prev) => ({ ...prev, gradient: gradientId }))
    }

    const isTagCountValid = (watch('tags')?.length || 0) >= 3

    // Information Content
    const InformationContent = (
        <div className='space-y-10'>
            {/* Visibility */}
            <div className='flex justify-start items-start space-x-10'>
                <div className='flex flex-col space-y-1 w-52 flex-shrink-0'>
                    <label htmlFor='visibility' className='font-medium'>
                        Guild Visibility<span className='text-red-500'>*</span>
                    </label>
                    <p className="text-sm text-net-grey-300">Set the visibility of your guild.</p>
                </div>
                <div className='flex-grow'>
                    <Listbox value={selectedVisibility?.name} onChange={handleVisibilityChange}>
                        <div className="relative">
                            <ListboxButton className="w-full px-4 py-2 flex items-center bg-net-grey-500 border border-net-grey-450 duration-150 rounded-lg font-medium text-lg">
                                {selectedVisibility ? selectedVisibility.name : 'Select visibility'}
                            </ListboxButton>
                            <ListboxOptions transition className="absolute mt-1 p-2 w-full transition-all ease-out data-[closed]:scale-95 data-[closed]:opacity-0 bg-net-grey-500 border border-net-grey-450 rounded-md shadow-lg z-10">
                                {visibilityOptions.map((option) => (
                                    <ListboxOption
                                        key={option.id}
                                        value={option}
                                        className={menuButtonStyle}
                                    >
                                        {option.name}
                                    </ListboxOption>
                                ))}
                            </ListboxOptions>
                        </div>
                    </Listbox>
                </div>
            </div>

            {/* Short Description */}
            <div className='flex justify-start items-start space-x-10'>
                <div className='flex flex-col space-y-1 w-52 flex-shrink-0'>
                    <label htmlFor='shortDescription' className='font-medium'>
                        Short description<span className='text-red-500'>*</span>
                    </label>
                    <p className='text-sm text-net-grey-300'>Briefly describe your guild.</p>
                </div>
                <div className='flex-grow'>
                    <input
                        maxLength={120}
                        type='text'
                        id='shortDescription'
                        placeholder='Incredibly awesome guild'
                        className={`
                            w-full px-4 py-2 border rounded-lg
                            outline-none transition-colors ease-linear duration-300
                            ${shortDescriptionError ? errorClasses : defaultClasses}
                        `}
                        {...register('shortDescription', {
                            required: 'This is a required field',
                            minLength: { value: 25, message: 'Minimum of 25 characters' },
                            onChange: (e) => {
                                setShortDescLength(e.target.value.length)
                            }
                        })}
                    />
                    <div className='flex justify-between items-center mt-1'>
                        <span className='text-sm text-red-500'>{formErrors.shortDescription?.message}</span>
                        <div className='text-sm text-net-grey-300'>{shortDescLength}/120</div>
                    </div>
                </div>
            </div>

            {/* Long Description */}
            <div className='flex justify-start items-start space-x-10'>
                <div className='flex flex-col space-y-1 w-52 flex-shrink-0'>
                    <label htmlFor='longDescription' className='font-medium'>
                        Long description<span className='text-red-500'>*</span>
                    </label>
                    <p className='text-sm text-net-grey-300'>Detailed information about your guild.</p>
                </div>
                <div className='flex-grow'>
                    <textarea
                        id='longDescription'
                        maxLength={5000}
                        placeholder='Describe your guild in detail...'
                        className={`
                            w-full min-h-[200px] max-h-96
                            px-4 py-2 border rounded-lg
                            outline-none transition-colors 
                            ease-linear duration-300
                            ${longDescriptionError ? errorClasses : defaultClasses}
                        `}
                        {...register('longDescription', {
                            required: 'This is a required field',
                            minLength: { value: 50, message: 'Minimum of 50 characters' },
                            onChange: (e) => {
                                setLongDescLength(e.target.value.length)
                            }
                        })}
                    />
                    <div className='flex justify-between items-center mt-1'>
                        <span className='text-sm text-red-500'>{formErrors.longDescription?.message}</span>
                        <div className='text-sm text-net-grey-300'>{longDescLength}/5000</div>
                    </div>
                </div>
            </div>

            {/* Invite Link */}
            <div className='flex justify-start items-start space-x-10'>
                <div className='flex flex-col space-y-1 w-52 flex-shrink-0'>
                    <label htmlFor='inviteLink' className='font-medium'>
                        Invitation Link<span className='text-red-500'>*</span>
                    </label>
                    <p className='text-sm text-net-grey-300'>Must be a Discord invite link.</p>
                </div>
                <div className='flex-grow'>
                    <input
                        type='text'
                        id='inviteLink'
                        placeholder='https://discord.gg/yourlink'
                        className={`
                            w-full px-4 py-2 border rounded-lg
                            outline-none transition-colors ease-linear duration-300
                            ${inviteLinkError ? errorClasses : defaultClasses}
                        `}
                        {...register('inviteLink', {
                            required: 'This is a required field',
                            pattern: {
                                value: /^https:\/\/discord\.gg\/[a-zA-Z0-9]+$/,
                                message: 'Link must be a valid Discord invite (e.g. https://discord.gg/abc123)'
                            }
                        })}
                    />
                    <span className='text-sm text-red-500'>{formErrors.inviteLink?.message}</span>
                </div>
            </div>

            {/* Tags */}
            <div className='flex justify-start items-start space-x-10'>
                <div className='flex flex-col space-y-1 w-52 flex-shrink-0'>
                    <label className='font-medium'>
                        Guild Tags<span className='text-red-500'>*</span>
                    </label>
                    <p className='text-sm text-net-grey-300'>Select tags describing your guild.</p>
                </div>
                <div className='flex-grow'>
                    <ul className='flex flex-wrap gap-3'>
                        {getVisibleTags(tags, true).map((tag) => (
                            <li key={tag.id}>
                                <input
                                    id={tag.id}
                                    type='checkbox'
                                    className='hidden peer'
                                    checked={watch('tags')?.includes(tag.id)}
                                    onChange={() => handleCheckboxChange(tag.id)}
                                />
                                <label 
                                    htmlFor={tag.id} 
                                    className={`
                                        flex items-center p-1.5 cursor-pointer bg-net-grey-500
                                        peer-first:border-net-grey-450 hover:bg-net-grey-400
                                        rounded-lg transition-colors duration-300
                                        peer-checked:hover:bg-net-purple-400
                                        peer-checked:border-net-purple-500
                                        peer-checked:bg-net-purple-300
                                        border border-transparent text-sm
                                    `}>

                                    <FaHashtag className='text-net-purple-500' />
                                    <span className='ml-1'>{tag.name}</span>
                                </label>
                            </li>
                        ))}

                        {watch('tags')?.length > 0 && (
                            <li key='reset-button'>
                                <button
                                    type='button'
                                    className={`
                                        flex items-center p-1.5 cursor-pointer duration-300
                                        rounded-lg bg-red-500/30 hover:bg-red-500/40 
                                        border border-red-500 text-white text-sm
                                    `}
                                    onClick={() => {
                                        setGuildInfo(prev => ({ ...prev, tags: [] }))
                                        setValue('tags', [], { shouldDirty: true })
                                    }}
                                >
                                    <i className='fi fi-rr-trash-list text-lg leading-none relative top-[1px]'></i>
                                    <span className='ml-2'>Reset tags</span>
                                </button>
                            </li>
                        )}
                    </ul>
                </div>
            </div>

            {/* Кнопка удаления только на первом табе, под тегами */}
            <hr className="border-t border-net-grey-450" />

            <div className='flex items-center justify-between'>
                <div>
                    <h4 className='text-lg font-medium text-red-500'>Delete this guild</h4>
                    <p className='text-net-grey-300 text-sm'>Deleting a guild is irreversible. All related data will be lost.</p>
                </div>

                <button
                        type='button'
                        onClick={handleDelete}
                        className='px-2 py-2 bg-red-500 rounded-lg hover:bg-red-600 transition-colors duration-150'
                    >
                        Delete Guild
                </button>
            </div>

            <hr className="border-t border-net-grey-450" />

            {/* Reset и Save Changes тоже на первом табе под удалением */}
            <div className='flex justify-end space-x-4 mt-6'>
                <button
                    type='button'
                    disabled={!isDirty}
                    onClick={() => {
                        if (initialValues) {
                            reset(initialValues)
                            setShortDescLength(initialValues.shortDescription.length)
                            setLongDescLength(initialValues.longDescription.length)
                            setGuildInfo((prev) => ({
                                ...prev,
                                hidden: initialValues.hidden,
                                tags: initialValues.tags,
                                gradient: initialValues.gradient,
                                shortDescription: initialValues.shortDescription,
                                longDescription: initialValues.longDescription,
                                inviteLink: initialValues.inviteLink,
                            }))
                            const visOption = visibilityOptions.find(o => o.value === initialValues.hidden)
                            setSelectedVisibility(visOption || visibilityOptions[0])
                        }
                    }}
                    className={`
                        px-6 py-3 text-md rounded-lg transition-colors duration-300 ease-in-out text-white font-semibold
                        ${!isDirty ? 'bg-net-grey-450 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'}
                    `}
                >
                    Reset
                </button>

                <button
                    type='submit'
                    disabled={!isFormValid || !isTagCountValid || !isDirty}
                    className={`
                        px-6 py-3 rounded-lg transition-all duration-300 text-white font-semibold
                        ${(!isFormValid || !isTagCountValid || !isDirty) 
                            ? 'bg-net-grey-450 cursor-not-allowed' 
                            : 'bg-net-purple-500 hover:bg-net-purple-900'
                        }
                    `}
                >
                    Save Changes
                </button>
            </div>
        </div>
    )

    // Personalization Content: без кнопки удаления, только Reset и Save внизу
    const PersonalizationContent = (
        <div className='space-y-10'>
            <div className="flex justify-between">
                <div className="space-y-4">
                    <h3 className="text-lg text-white font-medium">Choose the appropriate gradient:</h3>
                    {[0, 1, 2].map((rowIndex) => (
                        <div key={rowIndex} className="flex gap-2">
                            {gradients.slice(rowIndex * 6, rowIndex * 6 + 6).map((gradient) => (
                                gradient && gradient.colors && (
                                    <label key={gradient.id} className="flex items-center">
                                        <input
                                            type="radio"
                                            value={gradient.id}
                                            className="hidden peer"
                                            checked={guildInfo.gradient === gradient.id}
                                            onChange={() => handleGradientChange(gradient.id)}
                                        />
                                        <div
                                            className="w-10 h-10 rounded-lg cursor-pointer transition-colors duration-300 peer-checked:border-2 peer-checked:border-white"
                                            style={{
                                                background: `linear-gradient(rgba(0,0,0,0.1), rgba(0,0,0,0.1)), linear-gradient(to bottom right, ${gradient.colors[0]}, ${gradient.colors[1]})`,
                                            }}
                                        ></div>
                                    </label>
                                )
                            ))}
                        </div>
                    ))}

                    <div className='text-white font-medium text-base'>
                        Gradient <span className='text-net-grey-300'>#{parseInt(currentGradient.id, 10) + 1}</span> is selected. Great choice!
                    </div>
                </div>

                <div
                    className="w-[417px] h-[252px] flex items-center justify-center rounded-lg"
                    style={{
                        background: `linear-gradient(to bottom right, ${currentGradient.colors[0]}, ${currentGradient.colors[1]})`,
                    }}>
                    <div className="w-[415px] h-[250px] bg-[#1f222d] relative rounded-lg">
                        <div
                            className="absolute top-0 left-0 right-0 h-[45%] rounded-t-lg border-t-[2px] border-l-[2px] border-r-[2px] border-[#1f222d]"
                            style={{
                                background: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(to bottom right, ${currentGradient.colors[0]}, ${currentGradient.colors[1]})`,
                            }}>
                        </div>

                        {guildInfo.icon ? (
                            <img
                                src={getIconUrl(guildId, guildInfo.icon)}
                                alt="Avatar"
                                className="absolute top-[45%] left-[20px] w-[75px] h-[75px] rounded-full border-4 border-[#1f222d] transform -translate-y-1/2"/>
                        ) : (
                            <div
                                className="absolute top-[45%] left-[20px] w-[75px] h-[75px] border-4 border-[#1f222d] rounded-full transform -translate-y-1/2 flex items-center justify-center"
                            >
                                {createIcon({
                                    name: guildInfo.name,
                                    width: 'w-[70px]',
                                    height: 'h-[70px]',
                                })}
                            </div>
                        )}
                        <span className="absolute top-[46%] left-[105px] font-bold text-white text-lg">
                            {guildInfo.name || 'Guild Name'}
                        </span>

                        <ul className='absolute top-[63%] left-[20px] flex gap-2'>
                            {guildInfo.tags.slice(0,3).map(tagId => {
                                const tag = tags.find(t => t.id === tagId)
                                return (
                                    <li key={tag.id} className='flex items-center text-white bg-net-grey-500 p-1 text-sm rounded-lg transition-all duration-300 border border-net-grey-450'>
                                        <FaHashtag className='text-net-purple-500' />
                                        <span className='ml-1'>{tag.name}</span>
                                    </li>
                                )
                            })}
                            {guildInfo.tags.length > 3 && (
                                <li className='flex items-center text-white bg-net-grey-500 p-1 text-sm rounded-lg transition-all duration-300 border border-net-grey-450'>
                                    <FaHashtag className='text-net-purple-500' />
                                    <span className='ml-1'>+{guildInfo.tags.length - 3} more</span>
                                </li>
                            )}
                        </ul>

                        <div className='absolute bottom-2 left-[20px] right-[20px] flex items-center justify-between'>
                            <div className='flex items-center space-x-6 text-white'>
                                <div className='flex items-center'>
                                    <i className='fi fi-rr-users text-2xl leading-none'></i>
                                    <span className='ml-2 text-lg text-net-grey-300 font-medium'>{formatNumber(guildInfo.members.total)}</span>
                                </div>
                                <div className='flex items-center'>
                                    <i className='fi fi-rr-star text-xl leading-none mt-1'></i>
                                    <span className='ml-2 text-lg text-net-grey-300 font-medium'>0</span>
                                </div>
                            </div>
                            <div className='flex items-center space-x-2 font-medium'>
                                <button
                                    className='px-3 py-3 bg-net-white-100 duration-150 hover:bg-net-white-200 rounded-md leading-none'
                                    type='button'
                                >
                                    <i className='fi fi-rr-share text-lg leading-none'></i>
                                </button>
                                <button className='px-6 py-3 text-lg bg-net-purple-500 hover:bg-net-purple-900 text-white rounded-md leading-none'>
                                    Join
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <hr className="border-t border-net-grey-450" />

            {/* На втором табе только Reset и Save внизу */}
            <div className='flex justify-end space-x-4 mt-6'>

                <button
                    type='button'
                    disabled={!isDirty}
                    onClick={() => {
                        if (initialValues) {
                            reset(initialValues)
                            setShortDescLength(initialValues.shortDescription.length)
                            setLongDescLength(initialValues.longDescription.length)
                            setGuildInfo((prev) => ({
                                ...prev,
                                hidden: initialValues.hidden,
                                tags: initialValues.tags,
                                gradient: initialValues.gradient,
                                shortDescription: initialValues.shortDescription,
                                longDescription: initialValues.longDescription,
                                inviteLink: initialValues.inviteLink,
                            }))
                            const visOption = visibilityOptions.find(o => o.value === initialValues.hidden)
                            setSelectedVisibility(visOption || visibilityOptions[0])
                        }
                    }}
                    className={`
                        px-6 py-3 text-md rounded-lg transition-colors duration-300 ease-in-out text-white font-semibold
                        ${!isDirty ? 'bg-net-grey-450 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'}
                    `}
                >
                    Reset
                </button>

                <button
                    type='submit'
                    disabled={!isFormValid || !isTagCountValid || !isDirty}
                    className={`
                        px-6 py-3 rounded-lg transition-all duration-300 text-white font-semibold
                        ${(!isFormValid || !isTagCountValid || !isDirty) 
                            ? 'bg-net-grey-450 cursor-not-allowed' 
                            : 'bg-net-purple-500 hover:bg-net-purple-900'
                        }
                    `}
                >
                    Save Changes
                </button>
            </div>
        </div>
    )

    return (
        <div className='max-w-7xl mx-auto mt-10'>
            {/* Кнопка возвращения на страницу сервера */}
            <div className='mb-4'>
                <button
                    onClick={() => navigate(`/guilds/${guildId}`)}
                    className='px-4 py-2 bg-net-grey-500 hover:bg-net-grey-400 transition-colors duration-300 border border-net-grey-450 rounded-lg font-medium'
                >
                    <i className="fi fi-rr-left mr-2 relative top-[2px]"></i>
                    Back to Guild
                </button>
            </div>

            <div className='bg-net-grey-500 border border-net-grey-450 p-4 rounded-lg'>
                <h2 className='text-2xl font-bold mb-4'>Edit Guild: {guild?.name}</h2>
                
                <form onSubmit={handleSubmit(onSave)}>
                    <div className='flex w-full'>
                        {/* Вертикальные табы слева */}
                        <div className='flex flex-col w-48 border-r border-net-grey-450 pr-4 shrink-0'>
                            <button
                                className={`
                                    relative py-3 px-4 text-left font-medium text-lg rounded-lg mb-2 
                                    transition-colors duration-300
                                    ${activeTab === 'information' 
                                        ? 'text-white bg-net-purple-500/20'
                                        : 'text-white hover:bg-slate-500/10'}
                                `}
                                onClick={() => switchTab('information')}
                                type='button'
                            >
                                {activeTab === 'information' && (
                                    <span className="absolute left-0 top-0 bottom-0 w-1 bg-net-purple-500 rounded"></span>
                                )}
                                Information
                            </button>
                            <button
                                className={`
                                    relative py-3 px-4 text-left font-medium text-lg rounded-lg mb-2 
                                    transition-colors duration-300
                                    ${activeTab === 'personalization' 
                                        ? 'text-white bg-net-purple-500/20'
                                        : 'text-white hover:bg-slate-500/10'}
                                `}
                                onClick={() => switchTab('personalization')}
                                type='button'
                            >
                                {activeTab === 'personalization' && (
                                    <span className="absolute left-0 top-0 bottom-0 w-1 bg-net-purple-500 rounded"></span>
                                )}
                                Personalization
                            </button>
                        </div>

                        <div className='pl-10 flex-grow min-w-[800px]'>
                            <Transition
                                show={showContent}
                                appear
                                enter="transition-opacity duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="transition-opacity duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                                afterLeave={handleAfterLeave}
                            >
                                <div>
                                    {viewTab === 'information' && InformationContent}
                                    {viewTab === 'personalization' && PersonalizationContent}
                                </div>
                            </Transition>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
