import { useContext, useState } from 'react'
import { FaHashtag } from 'react-icons/fa'
import { StepContext } from '../StepContext'
import tags from '../../../../../constants/tags'
import { useForm } from 'react-hook-form'
import { handleTagChange, getVisibleTags } from '../../../../../utils/tags'
import { findGuildById } from '../../../../../utils/guilds.jsx'

export default function GuildInformation() {
    const { userGuilds, guildId, guildInfo, setGuildInfo, handleNext, handleBack } = useContext(StepContext)
    const { register, formState } = useForm({ mode: 'onChange' })

    const formErrors = formState.errors
    const isFormValid = formState.isValid
    const isTagCountValid = guildInfo.tags.length >= 3

    const shortDescriptionError = formErrors.shortDescription?.message
    const longDescriptionError = formErrors.longDescription?.message
    const inviteLinkError = formErrors.inviteLink?.message

    console.log(isTagCountValid, isFormValid)

    const defaultClasses = 'border-net-grey-450 bg-transparent focus:bg-slate-500/10'
    const errorClasses = 'border-red-500 bg-red-500/10 focus:border-red-500 focus:bg-red-500/10' 

    const [shortDescLength, setShortDescLength] = useState(guildInfo.shortDescription.length)
    const [longDescLength, setLongDescLength] = useState(guildInfo.longDescription.length)

    const guild = findGuildById(userGuilds, guildId)
    const displayedTags = getVisibleTags(tags, true)

    const handleCheckboxChange = (id) => {
        const updatedTags = handleTagChange(guildInfo.tags, id)
        setGuildInfo((prev) => ({...prev, tags: updatedTags}))
    }

    const handleSaveInfo = () => {
        console.log(guildInfo)
        handleNext()
    }

    return (
        <>
            <div className='bg-net-grey-500 border border-net-grey-450 p-4 rounded-lg space-y-8'>
                <div>
                    <h2 className='text-2xl font-bold'>Information about {guild?.name}</h2>
                    <p className='text-net-grey-300 font-medium'>Tell us a little bit about the features of your guild</p>
                </div>

                <form className='space-y-10'>

                    {/* short description */}
                    <div className='flex justify-start items-start space-x-10'>
                        <div className='flex flex-col space-y-1 w-52 flex-shrink-0'>
                            {/* short description label */}
                            <label htmlFor='shortDescription' className='font-medium'>
                                Short description<span className='text-red-500'>*</span>
                            </label>

                            <p className='text-sm text-net-grey-300'>
                                How is your guild unique?
                                <br/>
                                Describe in a few words.
                                <br/>
                                <span className='font-medium'>Note: </span> Used in Open Graph.
                            </p>
                        </div>

                        <div className='flex-grow'>
                            <input 
                                maxLength={120}
                                autoComplete='off'
                                type='text' id='shortDescription'
                                value={guildInfo.shortDescription}
                                placeholder='Incredibly awesome guild'
                                className={`
                                    w-full px-4 py-2 border rounded-lg
                                    outline-none transition-colors ease-linear duration-300

                                    ${shortDescriptionError ? errorClasses : defaultClasses}
                                `}

                                {...register('shortDescription', {
                                    required: {
                                        value: true,
                                        message: 'This is a required field'
                                    },
                                    minLength: {
                                        value: 25,
                                        message: 'Minimum of 25 characters'
                                    },
                                    onChange: (event) => {
                                        const value = event.target.value
                                        setShortDescLength(value.length)

                                        setGuildInfo((prev) => ({
                                            ...prev, shortDescription: value
                                        }))
                                    }
                                })}
                            />

                            <div className='flex justify-between items-center mt-1'>
                                <span className='text-sm text-red-500'>{shortDescriptionError}</span>
                                <div className='text-sm text-net-grey-300'>
                                    {shortDescLength}/120
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* long description */}
                    <div className='flex justify-start items-start space-x-10'>
                        <div className='flex flex-col space-y-1 w-52 flex-shrink-0'>
                            <label htmlFor='longDescription' className='font-medium'>
                                Long description<span className='text-red-500'>*</span>
                            </label>

                            <p className='text-sm text-net-grey-300'>
                                What does your guild offer?
                                <br/>
                                Educate your users.
                                <br/>
                                <span className='font-medium'>Note: </span> 
                                You can use Markdown to design the text.
                            </p>
                        </div>

                        <div className='flex-grow'>
                            <textarea 
                                autoComplete='off'
                                id='longDescription' maxLength={5000}
                                value={guildInfo.longDescription}
                                placeholder='Describe your guild in as much detail as possible...'
                                className={`
                                    w-full min-h-52 max-h-96
                                    px-4 py-2 border rounded-lg
                                    outline-none transition-colors 
                                    ease-linear duration-300

                                    ${longDescriptionError ? errorClasses : defaultClasses}
                                `}

                                {...register('longDescription', {
                                    required: {
                                        value: true,
                                        message: 'This is a required field'
                                    },
                                    minLength: {
                                        value: 50,
                                        message: 'Minimum of 50 characters'
                                    },
                                    onChange: (event) => {
                                        const value = event.target.value
                                        setLongDescLength(value.length)

                                        setGuildInfo((prev) => ({
                                            ...prev, longDescription: value
                                        }))
                                    }
                                })}
                            />

                            <div className='flex justify-between items-center mt-1'>
                                <span className='text-sm text-red-500'>{longDescriptionError}</span>
                                <div className='text-sm text-net-grey-300'>
                                    {longDescLength}/5000
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* invite link */}
                    <div className='flex justify-start items-start space-x-10'>
                        <div className='flex flex-col space-y-1 w-52 flex-shrink-0'>
                            <label htmlFor='inviteLink' className='font-medium'>
                                Invitation to the guild<span className='text-red-500'>*</span>
                            </label>

                            <p className='text-sm text-net-grey-300'>
                                Create a unique invitation link, so that users can easily find your guild.
                            </p>
                        </div>

                        <div className='flex-grow'>
                            <input
                                autoComplete='off'
                                type='text' id='inviteLink'
                                value={guildInfo.inviteLink}
                                placeholder='https://discord.gg/'
                                className={`
                                    w-full px-4 py-2 border rounded-lg
                                    outline-none transition-colors ease-linear duration-300

                                    ${inviteLinkError ? errorClasses : defaultClasses}
                                `}

                                {...register('inviteLink', {
                                    required: {
                                        value: true,
                                        message: 'This is a required field'
                                    },
                                    pattern: {
                                        value: /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[a-zA-Z0-9-._~:?#[\]\\@!$&'()*+,;%=]*)?$/,
                                        message: 'Invalid URL format'
                                    },
                                    onChange: (event) => {
                                        const value = event.target.value

                                        setGuildInfo((prev) => ({
                                            ...prev, inviteLink: value
                                        }))
                                    }
                                })}
                            />

                            <div className='items-center mt-1'>
                                <span className='text-sm text-red-500'>{inviteLinkError}</span>
                            </div>
                        </div>
                    </div>

                    {/* tags */}
                    <div className='flex justify-start items-start space-x-10'>
                        <div className='flex flex-col space-y-1 w-52 flex-shrink-0'>
                            <label className='font-medium'>
                                Guild Tags<span className='text-red-500'>*</span>
                            </label>

                            <p className='text-sm text-net-grey-300'>
                                Choose the tags that best describe the topic of your guild, so that users can easily find it.
                            </p>
                        </div>

                        <div className='flex-grow'>
                            <ul className='flex flex-wrap gap-3'>
                                {displayedTags.map((tag) => (
                                    <li key={tag.id} >
                                        <input 
                                            id={tag.id} 
                                            name={tag.name} 
                                            type='checkbox'
                                            className='hidden peer' 
                                            checked={guildInfo.tags.includes(tag.id)}
                                            onChange={() => handleCheckboxChange(tag.id)}
                                        />

                                        <label 
                                            htmlFor={tag.id} 
                                            className={`
                                                flex items-center p-1.5 cursor-pointer bg-net-grey-500
                                                peer-first:border-net-grey-450 hover:bg-net-grey-400
                                                rounded-lg transition-colors duration-300
                                                peer-checked:hover:bg-net-purple-400
                                                peer-checked:border-net-purple-500
                                                peer-checked:bg-net-purple-300
                                                border border-transparent
                                            `}>

                                            <FaHashtag className='text-net-purple-500' />
                                            <span className='ml-1'>{tag.name}</span>
                                        </label>
                                    </li>
                                ))}

                                {guildInfo.tags.length > 0 && (
                                    <li key='reset-button'>
                                        <button
                                            type='button'
                                            className={`
                                                flex items-center p-1.5 cursor-pointer duration-300
                                                rounded-lg bg-red-500/30 hover:bg-red-500/40 
                                                border border-red-500
                                            `}
                                            onClick={() => setGuildInfo(prev => ({ ...prev, tags: [] }))}
                                        >

                                            <i className='fi fi-rr-trash-list text-lg leading-none'></i>
                                            <span className='ml-2'>Reset tags</span>
                                        </button>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </form>

                <hr className='border-t border-net-grey-450 my-4' />
    
                <div className='flex justify-end space-x-4 mt-6'>
                    <button
                        onClick={handleBack}
                        className={`
                            bg-net-white-100 text-white text-lg rounded-lg
                            py-3 px-6 duration-300 hover:bg-net-white-200
                            transition-colors ease-in-out
                        `}
                    >
                        Back
                    </button>

                    <button
                        onClick={handleSaveInfo}
                        disabled={!isTagCountValid || !isFormValid}
                        className='px-4 py-2 text-lg text-white rounded-lg
                        bg-net-purple-500 hover:bg-net-purple-900
                        transition-colors duration-300 ease-in-out
                        disabled:opacity-50 disabled:cursor-not-allowed'>

                        Next
                    </button>
                </div>
            </div>
        </>
    )
}
